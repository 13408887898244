export const SET_TRAIN_SEARCH_PARAMETERS = 'SET_TRAIN_SEARCH_PARAMETERS'
export const SET_FLIGHT_SEARCH_PARAMETERS = 'SET_FLIGHT_SEARCH_PARAMETERS'
export const SET_FLIGHT_MULTI_ITEMS = 'SET_FLIGHT_MULTI_ITEMS'
export const SET_HOTEL_SEARCH_PARAMETERS = 'SET_HOTEL_SEARCH_PARAMETERS'
export const SET_CAR_SEARCH_PARAMETERS = 'SET_CAR_SEARCH_PARAMETERS'
export const SET_CAR_SEARCH_CACHE = 'SET_CAR_SEARCH_CACHE'
export const SET_FILTERS = 'SET_FILTERS'
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS'
export const SET_STOP_FILTERS = 'SET_STOP_FILTERS'
export const SET_TIME_FILTERS = 'SET_TIME_FILTERS'
export const SET_DURATION_FILTERS = 'SET_DURATION_FILTERS'
export const SET_COMPANIES_FILTERS = 'SET_COMPANIES_FILTERS'
export const RESET_SEARCH_PARAMETERS = 'RESET_SEARCH_PARAMETERS'
export const RESET_SEARCH_TRAVELLERS = 'RESET_SEARCH_TRAVELLERS'
export const SET_SEARCH_TRAVELLERS = 'SET_SEARCH_TRAVELLERS'
export const DISABLE_EXTEND_CHECKOUT = 'DISABLE_EXTEND_CHECKOUT'
