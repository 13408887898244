import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Menu from '@material-ui/core/Menu'
import { openLogoutModal } from '../../store/modals/modalAction'
import { useTranslation } from 'react-i18next'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { Link } from 'react-router-dom'
import DateRangeIcon from '@material-ui/icons/DateRange'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'none',
    },
  },
}))

const MenuItem = ({ handleClose, anchorEl }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { user, isAuthenticated, authType } = useSelector((state) => state.auth)

  const onShowlogoutModal = () => {
    dispatch(openLogoutModal())
    handleClose()
  }

  const onGoToAdmin = () => {
    document.location = '/admin/company/' + user.companyId
  }

  const {
    hideIDdocs,
    hideLoyalty,
    admin: showAdmin,
    hidePersonal,
    hidePassword,
  } = user || {}
  const isAgent = !!isAuthenticated && authType === 'agent' // is user auth type - agent

  const isWidget = window.parent !== window

  let items = []

  if (!user?.isBookingLink && !isAgent) {
    if (!hidePersonal) {
      items.push({
        title: 'nav personal',
        slug: `/profile/personal-information`,
        icon: InfoOutlinedIcon,
      })
    }

    if (!hideLoyalty) {
      items.push({
        title: 'nav programs',
        slug: `/profile/my-loyalty-programs`,
        icon: FavoriteBorderOutlinedIcon,
      })
    }

    if (!hideIDdocs) {
      items.push({
        title: 'nav id',
        slug: `/profile/id-documents`,
        icon: ContactMailIcon,
      })
    }

    if (!isWidget && !hidePassword) {
      items.push({
        title: 'nav pass',
        slug: `/profile/my-password`,
        icon: VpnKeyIcon,
      })
    }

    if (user?.bookingCalendar) {
      items.push({
        title: 'booking calendar',
        slug: `/company-trips-calendar`,
        icon: DateRangeIcon,
      })
    }

    let lang = i18n.languages[0] || 'sv'
    let policyField = `hasPolicyDocument${lang.toUpperCase()}`
    if (user?.[policyField]) {
      items.push({
        title: t('policy document'),
        slug: `/profile/policy-document`,
        icon: PictureAsPdfIcon,
      })
    }
  }

  const renderList = items.map((item, i) => (
    <ListItem key={i} component={Link} to={item.slug} onClick={handleClose}>
      <ListItemIcon style={{ minWidth: '40px' }}>
        {<item.icon color="primary" />}
      </ListItemIcon>
      <ListItemText className={classes.listItemText} primary={t(item.title)} />
    </ListItem>
  ))

  return (
    <Menu
      PaperProps={{
        style: {
          width: 300,
          borderRadius: '8px',
          boxShadow: '0px 5px 25px 0px rgba(0, 0, 0, .15)',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {renderList}

        {showAdmin && (
          <ListItem button onClick={onGoToAdmin}>
            <ListItemIcon style={{ minWidth: '40px' }}>
              <AccountBalanceIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={t('nav admin')}
            />
          </ListItem>
        )}

        {!isWidget && (
          <ListItem button onClick={onShowlogoutModal}>
            <ListItemIcon style={{ minWidth: '40px' }}>
              <ExitToApp color="primary" />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={t('nav logout')}
            />
          </ListItem>
        )}
      </List>
    </Menu>
  )
}

export default MenuItem
