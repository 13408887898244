import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import ChoosenTripCard from '../../../components/flights/choosenTripCard/choosenTripCard'
import { Box, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ChoosenJourney = () => {
  const { i18n } = useTranslation()
  const { outgoing, returning, selectedMulti } = useSelector(
    (state) => state.flights
  )

  const { currentFare, returnFare, multistopFares } = useSelector(
    (state) => state.flightDetails
  )

  const isMultiTicket = returning?.original?.isMultiTicket
  const isMultiStop = Object.values(selectedMulti).length > 0

  const renderTrips = useCallback(() => {
    let list = []
    if (isMultiStop) {
      for (const [stopId, flight] of Object.entries(selectedMulti)) {
        const fare = multistopFares[stopId]
        list.push(
          <ChoosenTripCard
            title={i18n.t('part trip')}
            trip={flight}
            fare={fare}
          />
        )
      }
    } else {
      list.push(
        <ChoosenTripCard
          title={i18n.t('chosen trip')}
          trip={outgoing}
          compareTrip={!!returning ? returning : null}
          fare={currentFare}
        />
      )

      if (returning) {
        list.push(
          <ChoosenTripCard
            home={true}
            title={i18n.t('chosen returntrip')}
            trip={returning}
            compareTrip={outgoing}
            fare={isMultiTicket ? returnFare : currentFare}
          />
        )
      }
    }

    const listWithDividers = list.flatMap((fragment, index) => [
      fragment,
      index < list.length - 1 ? (
        <Divider style={{ marginLeft: '20px', marginRight: '20px' }} />
      ) : null,
    ])

    return listWithDividers
  }, [outgoing, returning, selectedMulti])

  return <Box>{renderTrips()}</Box>
}

export default ChoosenJourney
