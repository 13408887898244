import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import { useSliderStyles } from './sliderStyles'
import theme from '../../../theme/theme'
import Box from '@material-ui/core/Box'
import { useSelector } from 'react-redux'

const TwoSideSlider = ({
  t,
  isOutgoing,
  convertStringToTime,
  convertTimeToString,
  handleTimeChange,
  disabled,
}) => {
  const time = useSelector((state) => state.flights.filters.time)
  const [departure, setDeparture] = useState(
    isOutgoing ? time.trip : time.returnTrip
  )
  const classes = useSliderStyles()

  useEffect(() => {
    setDeparture(isOutgoing ? time.trip : time.returnTrip)
  }, [isOutgoing, time.trip, time.returnTrip])

  return (
    <>
      <Box display="flex">
        <Typography
          className={classes.text}
          style={{
            color: disabled
              ? theme.palette.divider
              : theme.palette.common.black,
          }}
        >
          {!disabled ? t('outbound title') : t('search return')}
        </Typography>
        {!disabled && (
          <Typography className={classes.text} style={{ marginLeft: '16px' }}>
            {departure.from}—{departure.to}
          </Typography>
        )}
      </Box>
      <Slider
        defaultValue={[0, 24]}
        value={
          !disabled
            ? [
                convertStringToTime(departure.from),
                convertStringToTime(departure.to),
              ]
            : [0, 24]
        }
        step={0.5}
        color="primary"
        aria-labelledby="trip"
        onChange={(e, value) =>
          setDeparture({
            from: convertTimeToString(value[0]),
            to: convertTimeToString(value[1]),
          })
        }
        onChangeCommitted={(e, value) =>
          handleTimeChange(isOutgoing ? 'trip' : 'returnTrip', value)
        }
        max={24}
        disabled={disabled}
        classes={{ root: classes.root }}
      />
    </>
  )
}

export default TwoSideSlider
