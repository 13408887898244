const SET_FLIGHT_SEATS = 'SET_FLIGHT_SEATS'
const CLEAR_ALL_SEATS = 'CLEAR_ALL_SEATS'
const SET_FLIGHT_SEAT_LOADING = 'SET_FLIGHT_SEAT_LOADING'

const SET_OUTGOING_CATALOGUE = 'SET_OUTGOING_STANDALONE_CATALOGUE'
const SET_RETURN_CATALOGUE = 'SET_RETURNING_STANDALONE_CATALOGUEA'
const SET_USER_BAGGAGE = 'SET_USER_BAGGAGE'
const SET_CATALOGUE_LOADING = 'SET_CATALOGUE_LOADING'
const CLEAR_CATALOGUE_DATA = 'CLEAR_CATALOGUE_DATA'
const CLEAR_SELECTED_BAGGAGE = 'CLEAR_SELECTED_BAGGAGE'
const SET_OUTGOING_FAMILY_PRICING = 'SET_OUTGOING_FAMILY_PRICING'
const SET_RETURNING_FAMILY_PRICING = 'SET_RETURNING_FAMILY_PRICING'
const SET_FARE_LOADING = 'SET_FARE_LOADING'
const SET_FLIGHT_MIDDLESET_SETTINGS = 'SET_FLIGHT_MIDDLESET_SETTINGS'
const SET_OUTBOUND_FARE_FAMILY = 'SET_OUTBOUND_FARE_FAMILY'
const SET_RETURN_FARE_FAMILY = 'SET_RETURN_FARE_FAMILY'

const FLIGHT_DETAILS_CLEAR_ALL = 'FLIGHT_DETAILS_CLEAR_ALL'

const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'

const SET_PASSENGER_SEATS = 'SET_PASSENGER_SEATS'

const SET_MULTISTOP_FAMILY_PRICING = 'SET_MULTISTOP_FAMILY_PRICING'
const SET_MULTISTOP_FARE_FAMILY = 'SET_MULTISTOP_FARE_FAMILY'
const SET_MULTISTOP_BAGGAGE = 'SET_MULTISTOP_BAGGAGE'
const SET_MULTISTOP_CATALOGUE = 'SET_MULTISTOP_CATALOGUE'

export default {
  SET_OUTGOING_CATALOGUE,
  CLEAR_ALL_SEATS,
  SET_RETURN_CATALOGUE,
  SET_USER_BAGGAGE,
  SET_CATALOGUE_LOADING,
  SET_FLIGHT_SEATS,
  SET_OUTGOING_FAMILY_PRICING,
  SET_RETURNING_FAMILY_PRICING,
  SET_FLIGHT_MIDDLESET_SETTINGS,
  SET_OUTBOUND_FARE_FAMILY,
  SET_RETURN_FARE_FAMILY,
  CLEAR_CATALOGUE_DATA,
  CLEAR_SELECTED_BAGGAGE,
  SET_FARE_LOADING,
  SET_FLIGHT_SEAT_LOADING,
  FLIGHT_DETAILS_CLEAR_ALL,
  SET_TOTAL_PRICE,
  SET_PASSENGER_SEATS,
  SET_MULTISTOP_FAMILY_PRICING,
  SET_MULTISTOP_FARE_FAMILY,
  SET_MULTISTOP_BAGGAGE,
  SET_MULTISTOP_CATALOGUE,
}
