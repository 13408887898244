import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setAdditionalRecipientEmail } from '../../../store/checkout/checkoutActions'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  input: {
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.spacing(0.75),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(0.75),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
}))

const RecipientBlock = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { passengerData, passengerDataLoading } = useSelector(
    (state) => state.checkout
  )
  const { user } = useSelector((state) => state.auth)
  const [email, setEmail] = useState('')

  const handleChange = (evt) => {
    setEmail(evt.target.value)
  }

  useEffect(() => {
    // If user is not part of the booking, set user email as recipient.
    if (user && passengerData && Object.keys(passengerData).length > 0) {
      let userIsPartOfBooking = Object.keys(passengerData).find(
        (p) => p == user.uniqueId
      )
      if (!userIsPartOfBooking) {
        setEmail(user.email)
      } else {
        setEmail('')
      }
    }
  }, [passengerData, user, passengerDataLoading])

  useEffect(() => {
    dispatch(setAdditionalRecipientEmail(email))
  }, [email])

  return (
    <Box mx={isMobile ? 2 : 4} m={2} mt={4} mb={4}>
      <Box mb={2}>
        <Typography variant="h5" className={classes.bold}>
          {t('additional recipient')}
        </Typography>
      </Box>
      <Divider />

      {passengerDataLoading && (
        <Box mx={4} m={2} textAlign={'center'}>
          <CircularProgress />
        </Box>
      )}

      {!passengerDataLoading && (
        <Box mt={2} mb={8}>
          <TextField
            autoComplete={'off'}
            inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
            type="email"
            fullWidth
            error={!!error}
            value={email}
            helperText={error}
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            className={classes.input}
            InputProps={{ style: { fontWeight: 500 } }}
            onChange={handleChange}
            style={{ borderRadius: theme.spacing(0.75) }}
            placeholder={t('contact email')}
          />
        </Box>
      )}
    </Box>
  )
}
export default RecipientBlock
