import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import {
  Grid,
  Container,
  Box,
  Paper,
  List,
  useTheme,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import PersonalInformation from './_personalInformation'
import MyLoyaltyPrograms from './_myLoyaltyPrograms'
import IdDocuments from './_idDocuments'
import PolicyDocument from './_policyDocument'

// Icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { myInformation } from '../../containers/header/navLinksMobile'
import { useTranslation } from 'react-i18next'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import MyPassword from './_myPassword'
import { useDispatch, useSelector } from 'react-redux'
import { openEditInfoModal } from '../../store/modals/modalAction'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'scroll',
    flex: '1',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  links: {
    textDecoration: 'none',
    color: theme.palette.common.black,
    opacity: 0.6,
    fontSize: theme.spacing(2),
  },
  active: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4.5),
  },
  icon: {
    fontSize: theme.spacing(12),
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}))

const Profile = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  // const logout = () => dispatch(logOutUser())
  const { url } = useRouteMatch()
  const { t, i18n } = useTranslation()
  const { user } = useSelector((state) => state.auth)
  const isBig = useMediaQuery(theme.breakpoints.up(1025))
  const { hidePersonal, hidePassword } = user

  let myInfoRoutes = []

  if (!hidePersonal) {
    myInfoRoutes.push({
      name: 'Personal information',
      slug: `${url}/personal-information`,
      component: PersonalInformation,
      icon: InfoOutlinedIcon,
    })
  }

  myInfoRoutes.push(
    ...[
      {
        name: 'My loyalty programs',
        slug: `${url}/my-loyalty-programs`,
        component: MyLoyaltyPrograms,
        icon: FavoriteBorderOutlinedIcon,
      },
      {
        name: 'Id documents',
        slug: `${url}/id-documents`,
        component: IdDocuments,
        icon: ContactMailIcon,
      },
    ]
  )

  if (!hidePassword) {
    myInfoRoutes.push({
      name: 'My password',
      slug: `${url}/my-password`,
      icon: VpnKeyIcon,
      component: MyPassword,
    })
  }

  let lang = i18n.languages[0] || 'sv'
  let policyField = `hasPolicyDocument${lang.toUpperCase()}`
  if (user?.[policyField]) {
    myInfoRoutes.push({
      name: 'Policy document',
      slug: `${url}/policy-document`,
      icon: PictureAsPdfIcon,
      component: PolicyDocument,
    })
  }

  const renderRoutes = [...myInfoRoutes].map(
    (route, key) =>
      route.slug && (
        <Route
          key={key}
          path={route.slug}
          render={(props) => <route.component {...props} title={route.name} />}
        />
      )
  )

  const mobileLinks = myInformation(t, user, false)

  const openEditModal = (name, rows, section) => {
    dispatch(openEditInfoModal({ name, rows, section }))
  }

  const renderLinks = mobileLinks.map((link, index) => {
    return (
      <Box key={index} mt={0.5}>
        <ListItem
          onClick={
            link.rows
              ? () => openEditModal(link.name, link.rows, link.section)
              : null
          }
          to={link.rows ? undefined : link.slug}
          component={link.rows ? 'button' : Link}
          className={classes.nested}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            {<link.icon color="primary" />}
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={link.name} />
        </ListItem>
      </Box>
    )
  })

  return (
    <Paper
      square
      elevation={0}
      className={classes.paper}
      style={{ overflow: 'auto' }}
    >
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Box pt={!isDesktop ? 6 : 0}>
          <Grid container>
            {isBig && (
              <Grid item xs={12} sm={3} style={{ maxWidth: '280px' }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ width: '100%' }}
                  >
                    <Box
                      mb={2}
                      style={{
                        height: '96px',
                        width: '96px',
                        borderRadius: '50%',
                      }}
                    >
                      <AccountCircleIcon
                        color="primary"
                        className={classes.icon}
                      />
                    </Box>
                  </Box>
                  <Box
                    flexDirection="column"
                    display="flex"
                    justifyContent="center"
                  >
                    <Box>
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        {renderLinks}
                      </List>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={isBig ? 9 : 12}>
              <Switch>{renderRoutes}</Switch>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Paper>
  )
}

export default Profile
