import React from 'react'
import { Typography } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { setStopFilters } from '../../../store/search/searchAction'
import { useTranslation } from 'react-i18next'
import { useStyles } from './flightfilters'

const Stops = ({
  isOutgoing,
  searchOutgoing,
  searchReturning,
  searchMulti,
  isMultiStop,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const stops = useSelector((state) => state.flights.filters.stops)

  const changeStopsFilter = (event, value) => {
    const { name } = event.target
    dispatch(setStopFilters({ [name]: value }))
  }

  const filterChanges = (num) => {
    if (isMultiStop) {
      return searchMulti.filter((el) => el.via.length === num).length === 0
    } else if (isOutgoing) {
      return searchOutgoing.filter((el) => el.via.length === num).length === 0
    } else {
      return searchReturning.filter((el) => el.via.length === num).length === 0
    }
  }

  return (
    <>
      <Typography className={classes.weight}>{t('flter exchanges')}</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={stops['0']}
              onChange={changeStopsFilter}
              name="0"
              disabled={filterChanges(1)}
            />
          }
          label={t('filter directly')}
          className={classes.text}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={stops['1']}
              onChange={changeStopsFilter}
              name="1"
              disabled={filterChanges(2)}
            />
          }
          label={`1 ${t('detail byte')}`}
          className={classes.text}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={stops['2']}
              onChange={changeStopsFilter}
              name="2"
              disabled={filterChanges(3)}
            />
          }
          label={`2 ${t('detail byte')}`}
          className={classes.text}
        />
      </FormGroup>
    </>
  )
}

export default Stops
