import { useEffect, useState } from 'react'
import { fetchCityByIata } from '../repositories/flights'
import { fetchTrainCityByCode } from '../repositories/trains'
import { carLocationIsValid } from '../utils/carHelpers'

const useDestinations = (from, to, i18n, tripType = '') => {
  const [fromDest, setFrom] = useState(null)
  const [toDest, setTo] = useState(null)

  const fetchTest = async (dest, set) => {
    let code = dest
    // TODO this will be temporary fix
    // // in case object is coming
    if (!!dest && typeof dest === 'object') {
      code = dest.GENERIC_LOCATION_CODE
    }
    const rail = await fetchTrainCityByCode(code)
    if (rail) {
      set(rail)
    } else {
      set(dest)
    }
  }

  useEffect(() => {
    if (tripType === 'flight') {
      if (!fromDest && !toDest && from?.IATA && to?.IATA) {
        setTo(to)
        return setFrom(from)
      }
      const fetchCitByAita = async (aita, cb) => {
        const result = await fetchCityByIata(aita, i18n.language)
        return cb(result)
      }

      fetchCitByAita(from, setFrom)
      fetchCitByAita(to, setTo)
    } else if (tripType === 'car') {
      if (!fromDest && !toDest) {
        if (to && carLocationIsValid(to)) setTo(to)
        if (from && carLocationIsValid(from)) setFrom(from)
        setFrom(from)
      }
    } else {
      if (!fromDest) {
        fetchTest(from, setFrom)
      }
      if (!toDest) {
        fetchTest(to, setTo)
      }
    }
    // eslint-disable-next-line
  }, [from, to])

  const switchDestinations = () => {
    setTo(fromDest)
    setFrom(toDest)
  }

  return {
    switchDestinations,
    fromDest,
    setFrom,
    toDest,
    setTo,
  }
}

export default useDestinations
