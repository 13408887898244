import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import TrainsItemCard from './trainsCard'
import HotelsItemCard from './hotelCard'
import FlightCard from './flightCard'
import CarCard from './carCard'
import ChooseMissingTrip from './chooseMissingTrip'
import TransferCardFull from './tranferCardFull'
import Box from '@material-ui/core/Box'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import { fetchCityByIata } from '../../repositories/flights'
import i18n from '../../utils/i18n'
import moment from 'moment'

const viewHandler = {
  Train: TrainsItemCard,
  Hotel: HotelsItemCard,
  Flight: FlightCard,
  Transfer: TransferCardFull,
  Car: CarCard,
}

const ViewHandler = ({ disableEdit = false, contentOnly = false }) => {
  const { items, guaranteeLoading, costfieldsLoading } = useSelector(
    (state) => state.checkout
  )
  const { user } = useSelector((state) => state.auth)
  const {
    isCarsEnabled,
    isFlightsEnabled,
    isTrainsEnabled,
    isTransfersEnabled,
    isHotelsEnabled,
  } = useEnabledBookingTypesHook()
  const [airports, setAirports] = useState([])

  const isCheckoutLoading = !!guaranteeLoading || !!costfieldsLoading

  const addedTypes = []

  const renderComponents = items.map((component, key) => {
    const TagName = viewHandler[component.type]

    addedTypes.push(component.type)

    if (component.type === 'Flight' && component.isMultiStop) {
      return component.trips.map((trip, ind) => (
        <Box key={ind} mt={ind === 0 ? 0 : 2} mb={0}>
          <TagName
            type={component.type}
            disableEdit={disableEdit}
            model={trip}
            contentOnly={contentOnly}
            index={key}
          />
        </Box>
      ))
    }

    return (
      <Box key={key} mt={key === 0 ? 0 : 2} mb={0}>
        <TagName
          type={component.type}
          disableEdit={disableEdit}
          model={component}
          contentOnly={contentOnly}
          index={key}
        />
      </Box>
    )
  })

  useEffect(() => {
    const getAirports = async () => {
      let list = []
      for (const item of items.filter((item) => item.type === 'Flight')) {
        const { outgoing, returning } = item
        const iata = outgoing?.to?.shortName

        const location = await fetchCityByIata(iata, i18n.language)
        if (location) {
          let takeDate = moment(outgoing?.arrival)
          if (!takeDate.isValid()) continue
          takeDate = takeDate.add(30, 'minutes')

          let dropDate = null
          if (!!returning) {
            dropDate = moment(returning?.departure)
            if (!dropDate.isValid()) continue
            dropDate = dropDate.subtract(30, 'minutes')
          } else {
            dropDate = takeDate.clone().add(1, 'day')
          }

          const addItem = (description, lat, lng, isAirport) => {
            list.push({
              lat: lat,
              lng: lng,
              description: description,
              takeDate,
              dropDate,
              isAirport,
              item,
            })
          }
          addItem(location['Airport SWE'], location.lat, location.lng, true)

          const [cityLat, cityLng] = location['Hotel Coordinates']
            ?.split(',')
            .map((n) => Number(n.trim()))

          addItem(location['City SWE'], cityLat, cityLng, false)
        }
      }
      setAirports(list)
    }

    getAirports()
  }, [items])

  const hotelPresets = useMemo(() => {
    const presets = []

    const addHotelPreset = (type, to, toCity, arrival, departure, tag) => {
      const passengers = flightItem
        ? flightItem.passengers
        : trainItem.passengers
      presets.push({
        to,
        toCity,
        arrival,
        departure,
        passengers,
        type,
        tag,
      })
    }

    /*
   Hotel presets for flight and train

  */
    const trainItem = items.find((item) => item.type === 'Train')
    const flightItem = items.find((item) => item.type === 'Flight')

    // Train only
    if (!!trainItem && !flightItem) {
      let { outboundTrip, returnTrip } = trainItem
      let { railend } = outboundTrip.train.scheduleSolution

      let arrival = outboundTrip.train.scheduleSolution.railend.dateTime
      let departure = returnTrip?.train?.scheduleSolution?.railstart?.dateTime

      addHotelPreset(
        'train',
        railend.raillocationCode,
        railend.locationName,
        arrival,
        departure,
        't'
      )
    }

    // Flight only
    if (!!flightItem && !trainItem) {
      let { outgoing, returning } = flightItem

      if (!!flightItem.isMultiStop) {
        // Multistop flight
        flightItem.trips.forEach((t, ind) => {
          if (!t?.outgoing) return false

          addHotelPreset(
            'flight',
            t.outgoing.to.shortName,
            t.outgoing.to.city,
            t.outgoing.arrival,
            null,
            `ms${ind}`
          )
        })
      } else {
        addHotelPreset(
          'flight',
          outgoing.to.shortName,
          outgoing.to.city,
          outgoing.arrival,
          returning ? returning.departure : null,
          'f'
        )
      }
    }

    // Handle more scenarios between flight and train
    const handleFlightAndTrainHotels = () => {
      const { outboundTrip: trainOutboundTrip, returnTrip: trainReturnTrip } =
        trainItem
      const { outgoing: flightOutgoing, returning: flightReturning } =
        flightItem

      const trainOutboundSolution = trainOutboundTrip.train.scheduleSolution
      const trainReturnSolution =
        trainReturnTrip?.train?.scheduleSolution || null

      const trainArrival = moment(
        trainOutboundTrip.train.scheduleSolution.railend.dateTime
      )
      const flightArrival = moment(flightOutgoing.arrival)

      const trainDeparture = trainReturnTrip
        ? moment(trainReturnSolution.railstart.dateTime)
        : null
      const flightDeparture = flightReturning
        ? moment(flightReturning.departure)
        : null

      // Scenario 1: Stay at hotel between train arrival and flight return departure
      if (
        flightReturning &&
        trainDeparture?.isBefore(flightDeparture) &&
        moment(flightDeparture).isSame(trainDeparture, 'day') === false
      ) {
        addHotelPreset(
          'flight',
          flightReturning.from.shortName,
          flightReturning.from.city,
          trainDeparture,
          flightDeparture,
          '1'
        )
      }

      // Scenario 2: Stay at hotel between flight arrival and train return departure
      if (
        trainReturnTrip &&
        flightDeparture?.isBefore(trainDeparture) &&
        moment(flightDeparture).isSame(trainDeparture, 'day') === false
      ) {
        addHotelPreset(
          'train',
          trainReturnSolution.railstart.raillocationCode,
          trainReturnSolution.railstart.locationName,
          flightDeparture,
          trainDeparture,
          '2'
        )
      }

      // Scenario 3: Stay at hotel before flight departure
      if (
        flightOutgoing &&
        moment(flightOutgoing.departure).isBefore(trainArrival) &&
        moment(flightOutgoing.departure).isSame(trainArrival, 'day') === false
      ) {
        addHotelPreset(
          'flight',
          flightOutgoing.to.shortName,
          flightOutgoing.to.city,
          moment(flightOutgoing.departure),
          trainArrival,
          '3'
        )
      }

      // Scenario 4: Stay at hotel at train destination
      if (
        trainReturnTrip &&
        trainDeparture?.isAfter(flightArrival) &&
        flightArrival.isBefore(trainArrival)
      ) {
        addHotelPreset(
          'train',
          trainReturnSolution.railstart.raillocationCode,
          trainReturnSolution.railstart.locationName,
          trainArrival,
          trainDeparture,
          '4'
        )
      }

      // Scenario 5: Stay at hotel at flight destination
      if (flightReturning && trainDeparture?.isAfter(flightDeparture)) {
        addHotelPreset(
          'flight',
          flightOutgoing.to.shortName,
          flightOutgoing.to.city,
          flightArrival,
          flightDeparture,
          '5'
        )
      }

      // Scenario 6: Stay at hotel between train arrival and flight departure
      if (
        flightArrival.isAfter(trainArrival) &&
        trainReturnSolution &&
        moment(flightArrival).isSame(trainArrival, 'day') === false
      ) {
        addHotelPreset(
          'train',
          trainReturnSolution.railstart.raillocationCode,
          trainReturnSolution.railstart.locationName,
          trainArrival,
          flightArrival,
          '6'
        )
      }
    }

    /**
     * Train + Flight
     */
    if (!!flightItem && !!trainItem) {
      handleFlightAndTrainHotels()
    }
    presets.sort((a, b) =>
      moment(a.arrival).isBefore(moment(b.arrival)) ? -1 : 1
    )
    // console.log(presets)

    // Hotel presets for flight
    /*
  if (!!flightItem) {
    if (!!flightItem.isMultiStop) {
      // Multistop flight
      flightItem.trips.forEach((t, ind) => {
        if (!t?.outgoing) return false
        hotelPresets.push({
          from: t.outgoing.from.shortName,
          fromCity: t.outgoing.from.city,
          to: t.outgoing.to.shortName,
          toCity: t.outgoing.to.city,
          arrival: t.outgoing.arrival,
          key: `${t.outgoing.from.shortName}-${t.outgoing.to.shortName}-${t.outgoing.arrival}`,
          passengers: flightItem.passengers,
          tripIndex: ind,
          type: 'flight',
        })
      })
    } else {
      // Normal flight
      let { outgoing, returning } = flightItem
      hotelPresets.push({
        to: outgoing.to.shortName,
        toCity: outgoing.to.city,
        arrival: outgoing.arrival,
        departure: returning ? returning.departure : null,
        key: `${outgoing.from.shortName}-${outgoing.to.shortName}-${outgoing.arrival}`,
        passengers: flightItem.passengers,
        type: 'flight',
      })
    }
  }
  */

    return presets
  }, [items])

  const missedTrips = []

  //only 2 of 3 types ('Car', 'Flight', 'Train') can be added to cart
  const twoOfThree = ['Car', 'Flight', 'Train'].filter((ct) =>
    addedTypes.includes(ct)
  )
  const isOneOfThree = twoOfThree.length <= 1
  const ifMainTripExists =
    addedTypes.includes('Flight') || addedTypes.includes('Train')

  if (
    !addedTypes.includes('Car') &&
    isCarsEnabled &&
    (isOneOfThree || user.carInMultiTrip)
  )
    missedTrips.push('car')
  if (
    !addedTypes.includes('Flight') &&
    isFlightsEnabled &&
    !!user &&
    isOneOfThree
  )
    missedTrips.push('flight')
  if (
    !addedTypes.includes('Train') &&
    isTrainsEnabled &&
    !!user &&
    isOneOfThree
  )
    missedTrips.push('train')

  if (hotelPresets.length > 0 && isHotelsEnabled) {
    missedTrips.push('hotel')
  }

  const isOnlyTrainEnabled =
    isTrainsEnabled && !isCarsEnabled && !isFlightsEnabled

  if (
    isTransfersEnabled &&
    ifMainTripExists &&
    !addedTypes.includes('Transfer') &&
    !isOnlyTrainEnabled
  )
    missedTrips.push('transfer')

  if (!disableEdit && !!missedTrips?.length && !isCheckoutLoading) {
    const tmpKey = renderComponents.length
    renderComponents.push(
      <Box key={tmpKey} mt={tmpKey === 0 ? 0 : 2} mb={0}>
        <ChooseMissingTrip
          key={'missing_trip_checkout_block'}
          elements={missedTrips}
          airports={airports}
          hotelBtnPresets={hotelPresets}
        />
      </Box>
    )
  }

  return <Box>{renderComponents}</Box>
}

export default ViewHandler
