import React, { memo, useState } from 'react'
import AuthWrapper from '../../containers/authWrapper/authWrapper'
import { Box, Typography, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Register = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <AuthWrapper>
      <Box mb={10} mt={5}>
        <Typography align="center" variant="h3">
          Registrera dig
        </Typography>
        <Box>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: false }}
            id="outlined-basic"
            label={email ? '' : t('placeholder email')}
            variant="outlined"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <TextField
            fullWidth
            InputLabelProps={{ shrink: false }}
            id="outlined-basic"
            label={password ? '' : t('placeholder pass')}
            variant="outlined"
            value={email}
            onChange={({ target }) => setPassword(target.value)}
          />
        </Box>
      </Box>
    </AuthWrapper>
  )
}

export default memo(Register)
