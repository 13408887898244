import React, { useState, Fragment, useContext, useEffect } from 'react'
import moment from 'moment'
import _ from 'lodash'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import FlightItem from '../../components/flights/flightSearchCard/flightItem'
import Divider from '@material-ui/core/Divider'
import FlightSkeletons from '../../components/reusable/skeletons/flightSkeleton'
import DateHeader from '../trains/trainContainers/dateHeader.js/dateHeader'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import MobileSortHeader from './parts/mobileSortHeader'
import { CompaniesContext } from '../../components/flights/filter/companiesContext'
import SortBy from '../../components/reusable/sortBy'
import useSort from '../../hooks/useSortHook'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', marginBottom: theme.spacing(10) },
  align: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const FlightSearchContainer = ({
  returning,
  minimumPrice,
  isOutgoing,
  isMulti,
}) => {
  const { t } = useTranslation()

  const sortTypes = [
    { type: 'price', label: t('sorted cheapest') },
    { type: 'arrival', label: t('sorted arrival') },
    { type: 'departure', label: t('sorted departure') },
    { type: 'fastest', label: t('sorted fastest') },
    { type: 'deal', label: t('sorted deal') },
    { type: 'emission', label: t('emission') },
  ]

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  const { searchOutgoing, searchReturning, searchError, searchMulti, filters } =
    useSelector((state) => state.flights)
  const [companies] = useContext(CompaniesContext)
  const { from, to, tripNumber } = useParams()

  useEffect(() => setExpanded(false), [from, to, tripNumber])

  const { sort, anchorEl, handleClick, handleClose } = useSort(
    sortTypes,
    searchOutgoing,
    searchReturning,
    'flight',
    'cheapest'
  )
  const filterFlights = React.useCallback(
    (flights) => {
      // if (isMulti) return flights
      const filteredStops = flights.filter(
        (el) =>
          (filters.stops['0'] && el.via.length === 1) ||
          (filters.stops['1'] && el.via.length === 2) ||
          (filters.stops['2'] && el.via.length === 3)
      )
      const filteredDepartureTime = filteredStops.filter((el) => {
        if (returning) {
          return (
            moment(el.departTime, ['h:m a', 'H:m']) >=
              moment(filters.time.returnTrip.from, ['h:m a', 'H:m']) &&
            moment(el.departTime, ['h:m a', 'H:m']) <=
              moment(filters.time.returnTrip.to, ['h:m a', 'H:m'])
          )
        } else {
          return (
            moment(el.departTime, ['h:m a', 'H:m']) >=
              moment(filters.time.trip.from, ['h:m a', 'H:m']) &&
            moment(el.departTime, ['h:m a', 'H:m']) <=
              moment(filters.time.trip.to, ['h:m a', 'H:m'])
          )
        }
      })

      const filteredDuration = filteredDepartureTime.filter((el) =>
        returning
          ? el.travelTime <= filters.travelTime.returnTrip
          : el.travelTime <= filters.travelTime.trip
      )

      const companyNames = companies.reduce(
        (acc, el) => (el.state ? acc : [...acc, { company: el.slug }]),
        []
      )

      return _.differenceBy(filteredDuration, companyNames, 'company')
    },
    [filters, companies, returning]
  )

  // Checks which list should be used
  const searchResults = isMulti
    ? searchMulti
    : returning
    ? searchReturning
    : searchOutgoing

  const handleChange = (panel) => (_event, isExpanded) => {
    return setExpanded(isExpanded ? panel : false)
  }

  const renderFlighItems = React.useMemo(
    () =>
      filterFlights(searchResults)
        .sort((first, second) => {
          const firstPrice = first?.price || 0
          const secondPrice = second?.price || 0

          switch (sort?.type) {
            case 'deal':
              return (
                firstPrice +
                (first.isDealFare || first.isCorporateFare ? 0 : 1000000) -
                (secondPrice +
                  (second.isDealFare || second.isCorporateFare ? 0 : 1000000))
              )
            case 'price':
              return firstPrice - secondPrice
            case 'departure':
              return (
                new Date('1970/01/01 ' + first.departTime) -
                new Date('1970/01/01 ' + second.departTime)
              )
            case 'fastest':
              if (first.travelTime - second.travelTime !== 0) {
                return first.travelTime - second.travelTime
              }
              return firstPrice - secondPrice
            case 'arrival':
              return first.arrival.localeCompare(second.arrival)
            case 'emission':
              return first.co2 - second.co2
            default:
              return firstPrice - secondPrice
          }
        })
        .map((flight, i) => (
          <Fragment key={i}>
            <Box px={isMobile ? 2 : 3} pb={1} pt={0}>
              <FlightItem
                index={i}
                flight={flight}
                expanded={expanded}
                handleChange={handleChange}
                minimumPrice={minimumPrice}
              />
            </Box>
            <Divider />
          </Fragment>
        )),
    [filterFlights, searchResults, expanded, isMobile, minimumPrice, sort]
  )

  const getTitle = () => {
    if (isMulti) {
      let currentFrom = from.split('-')[tripNumber]
      let currentTo = to.split('-')[tripNumber]
      return `${t('departure')} ${currentFrom}-${currentTo}`
    } else {
      return returning ? t('return trip') : t('choose departure')
    }
  }

  if (searchError) {
    return (
      <Paper elevation={0}>
        <Box pt={2} minHeight="90vh">
          <Box mt={10}>
            <Typography align="center" variant="h5" className={classes.weight}>
              {t('not find trips')}
            </Typography>
          </Box>
        </Box>
      </Paper>
    )
  }

  const title = getTitle()

  return (
    <Paper elevation={0} style={{ borderRadius: '12px' }}>
      <Box>
        <Box
          p={3}
          pb={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!searchResults.length ? (
            <Box height={41} />
          ) : (
            <Box width="100%" style={{ minWidth: '300px' }}>
              <Typography
                align={!isMobile ? 'left' : 'center'}
                variant="h4"
                className={classes.weight}
                style={{ textWrap: 'nowrap' }}
              >
                {title}
              </Typography>
            </Box>
          )}
          {!isMobile && searchResults.length ? (
            <Box
              display="flex"
              width="100%"
              justifyContent={isMobile ? 'flex-start' : 'flex-end'}
            >
              <SortBy
                sort={sort}
                sortTypes={sortTypes}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                searchResults={searchResults}
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
        <Divider />
        <DateHeader flight />
        {isMobile && searchResults.length ? (
          <>
            <MobileSortHeader
              sort={sort}
              sortTypes={sortTypes}
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              isOutgoing={isOutgoing}
            />
            <Divider />
          </>
        ) : (
          ''
        )}
        <Box className={classes.root}>
          {!searchResults.length ? (
            <Box>
              <FlightSkeletons />
            </Box>
          ) : (
            renderFlighItems
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default FlightSearchContainer
