import React, { memo, useCallback, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import FlightTimeline from '../../../components/myTrip/flightTimeline'
import HotelTimeline from '../../../components/myTrip/hotelTimeline'
import TransferTimeline from '../../../components/myTrip/transferTimeline'
import CarTimeline from '../../../components/myTrip/carTimeline'
import TrainTimeline from '../../../components/myTrip/trainTimeline'
import MiscTimeline from '../../../components/myTrip/miscTimeline'
import moment from 'moment'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'
import { Grid, Typography } from '@material-ui/core'
import {
  DirectionsBus,
  DirectionsRailway,
  InsertInvitation,
  KeyboardArrowRight,
  DirectionsCar,
  LocalTaxi,
  ArrowForward,
} from '@material-ui/icons'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getTaxiCompanyFromProduct } from '../../../utils/taxiHelpers'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', paddingTop: '24px' },
  justiceBetween: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    backgroundColor: theme.palette.divider,
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  center: {
    textAlign: 'center',
  },
  showTicketBtn: {
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
    letterSpacing: 1.1,
    padding: theme.spacing(1, 2),
    fontSize: '13px',
  },
  cancelBtn: {
    borderRadius: theme.spacing(3),
    letterSpacing: 1.1,
    padding: theme.spacing(1, 2),
    fontSize: '13px',
  },
  timeLineIcon: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    marginTop: '10px',
  },
  timeLineConnector: {
    color: theme.palette.grey,
    width: '1px',
    marginBottom: '10px',
    borderBottomColor: theme.palette.grey[600],
    borderTopColor: theme.palette.grey[600],
    borderLeftColor: theme.palette.grey[600],
    borderRightColor: theme.palette.grey[600],
  },
  timeLineRoot: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  partTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
  },
  partSubtitle: {
    fontSize: '14px',
    color: theme.palette.grey[600],
  },
  dot: {
    height: '4px',
    width: '4px',
    verticalAlign: 'middle',
    backgroundColor: theme.palette.grey[600],
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '8px',
    marginRight: '8px',
  },
  expandBtnContainer: {
    alignContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  cancelledText: {
    marginLeft: '10px',
    fontSize: '14px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    display: 'inline-block',
  },
}))

const TripDetails = ({
  model,
  enableCancel = true,
  onExpand = null,
  onClose = null,
  mainRef = null,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  /**
   * Given a booking, return an array of chronological parts of the trip with some meta data.
   * @param {*} model
   */
  const parts = useMemo(() => {
    let parts = []
    if (model.rentalCar) {
      const days = Math.max(
        1,
        moment(model.rentalCar.endDate).diff(
          moment(model.rentalCar.startDate),
          'days'
        )
      )

      parts.push({
        icon: <DirectionsCar />,
        type: 'rentalCar',
        component: (
          <CarTimeline
            model={model}
            enableCancel={enableCancel}
            onClose={onClose}
            mainRef={mainRef}
          />
        ),
        ts: moment(model.rentalCar.startDate).toDate(),
        titles: [
          _.capitalize(
            model.rentalCar.carCompanyData.companyName +
              ' ' +
              t('rental car').toLowerCase()
          ),
        ],
        subtitles: [
          t('pick-up search') +
            ' ' +
            moment(model.rentalCar.startDate).format('HH:mm'),
          `${days} ${t(days > 1 ? 'day_plural' : 'day').toLowerCase()}`,
        ],
        cancelled: model.rentalCar.cancelled,
      })
    }
    if (model.flights) {
      let outboundTrip = model.flights.filter(
        (flight) => flight.type === 'trip'
      )
      let returnTrip = model.flights.filter(
        (flight) => flight.type === 'returnTrip'
      )

      const outboundStops = outboundTrip.length - 1

      parts.push({
        type: 'flight',
        icon: <FlightTakeoffIcon />,
        component: (
          <FlightTimeline
            model={model}
            price={model.prices?.flight}
            enableCancel={enableCancel}
            onClose={onClose}
            mainRef={mainRef}
          />
        ),
        ts: moment(outboundTrip[0].departure).toDate(),
        titles: [
          outboundTrip[0].from,
          outboundTrip[outboundTrip.length - 1].to,
        ],
        subtitles: [
          t('departure at') +
            ' ' +
            moment(outboundTrip[0].departure).format('HH:mm'),
          outboundStops > 0
            ? `${outboundStops} ${outboundStops > 1 ? t('stops') : t('stop')}`
            : t('filter directly').toLowerCase(),
        ],
        cancelled: outboundTrip[0].cancelled,
      })
      if (returnTrip.length > 0) {
        const returnStops = returnTrip.length - 1

        parts.push({
          type: 'flight',
          icon: <FlightTakeoffIcon />,
          component: (
            <FlightTimeline
              model={model}
              price={model.prices?.flight}
              enableCancel={enableCancel}
              onClose={onClose}
              mainRef={mainRef}
            />
          ),
          ts: moment(returnTrip[0].departure).toDate(),
          titles: [returnTrip[0].from, returnTrip[returnTrip.length - 1].to],
          subtitles: [
            t('departure at') +
              ' ' +
              moment(returnTrip[0].departure).format('HH:mm'),
            returnStops > 0
              ? `${returnStops} ${returnStops > 1 ? t('stops') : t('stop')}`
              : t('filter directly').toLowerCase(),
          ],
          cancelled: returnTrip[0].cancelled,
        })
      }
    }

    if (model.transfers?.length) {
      parts.push(
        ...model.transfers.map((transfer) => {
          console.log(`${transfer.fromDate} ${transfer.fromTime}`)

          const parseDate = (str, locale) => {
            let d = moment(str, 'DD MMMM YYYY HH:mm', locale)
            if (d.isValid()) return d
          }
          const dateStr = `${transfer.fromDate} ${transfer.fromTime}`
          const fromDate = parseDate(dateStr, 'sv') || parseDate(dateStr, 'en')

          const companyName = getTaxiCompanyFromProduct(transfer.productCode)
          const productTypeText = t(`transfer product ${transfer.productCode}`)

          const title = ['FBUS', 'RAILE'].includes(transfer.productCode)
            ? companyName
            : `${companyName} (${productTypeText})`

          return {
            type: 'transfer',
            icon:
              transfer.taxiType === 'flightBus' ||
              transfer.productCode === 'FBUS' ? (
                <DirectionsBus />
              ) : (
                <LocalTaxi />
              ),
            component: (
              <TransferTimeline
                model={model}
                transfer={transfer}
                price={model.prices?.transfer}
                enableCancel={enableCancel}
                onClose={onClose}
                mainRef={mainRef}
              />
            ),
            ts: fromDate.toDate(),
            titles: [title],
            subtitles: [t('departure at') + ' ' + fromDate.format('HH:mm')],
            cancelled: transfer.cancelled,
          }
        })
      )
    }

    if (model.hotel) {
      const listOfHotels = Array.isArray(model.hotel)
        ? model.hotel
        : [model.hotel]
      for (let [hotelIndex, hotel] of listOfHotels.entries()) {
        const days = moment
          .duration(moment(hotel.endDate).diff(moment(hotel.startDate)))
          .asDays()

        parts.push({
          type: 'hotel',
          icon: <InsertInvitation />,
          component: (
            <HotelTimeline
              enableCancel={enableCancel}
              model={model}
              hotel={hotel}
              price={model.prices?.hotel}
              onClose={onClose}
              mainRef={mainRef}
              hotelIndex={Array.isArray(model.hotel) ? hotelIndex : null}
            />
          ),
          ts: moment(hotel.startDate + ' 23:59').toDate(), // TODO: checkin time
          titles: [hotel.title],
          subtitles: [
            'Incheckning kl 15:00',
            days + ' ' + (days > 1 ? t('night') : t('one night')),
          ],
          cancelled: hotel.cancelled,
        })
      }
    }

    if (model.rails) {
      const { outbound, return: inbound } = model.rails
      const outboundSegCount = outbound.segments.length
      parts.push({
        type: 'rail',
        icon: <DirectionsRailway />,
        component: (
          <TrainTimeline
            model={model}
            enableCancel={enableCancel}
            outboundPrice={model.prices?.railOutbound}
            returnPrice={model.prices?.railReturn}
            onClose={onClose}
            mainRef={mainRef}
          />
        ),
        ts: moment(outbound.railstart.dateTime).toDate(),
        titles: [
          outbound.railstart.locationName,
          outbound.railend.locationName,
        ],
        subtitles: [
          t('departure at', {
            time: moment(outbound.railstart.dateTime).format('HH:mm'),
          }),
          outboundSegCount > 1
            ? `${outboundSegCount - 1} ${
                outboundSegCount > 2 ? t('stops') : t('stop')
              }`
            : t('filter directly').toLowerCase(),
        ],
        cancelled: outbound.cancelled,
      })

      if (inbound) {
        const returnSegCount = inbound.segments.length
        parts.push({
          type: 'rail',
          icon: <DirectionsRailway />,
          component: (
            <TrainTimeline
              model={model}
              enableCancel={enableCancel}
              outboundPrice={model.prices?.railOutbound}
              returnPrice={model.prices?.railReturn}
              onClose={onClose}
              mainRef={mainRef}
            />
          ),
          ts: moment(inbound.railstart.dateTime).toDate(),
          titles: [
            inbound.railstart.locationName,
            inbound.railend.locationName,
          ],
          subtitles: [
            t('departure at', {
              time: moment(inbound.railstart.dateTime).format('HH:mm'),
            }),
            returnSegCount > 1
              ? `${returnSegCount - 1} ${
                  returnSegCount > 2 ? t('stops') : t('stop')
                }`
              : t('filter directly').toLowerCase(),
          ],
          cancelled: inbound.cancelled,
        })
      }

      if (model.misc) {
        parts.push(
          ...model.misc?.map((m) => ({
            type: m.type,
            icon: <DirectionsRailway />,
            component: (
              <MiscTimeline model={model} onClose={onClose} mainRef={mainRef} />
            ),
            ts: moment(model.created).toDate(),
            titles: ['-'],
            subtitles: ['-'],
            enableCancel: false,
          }))
        )
      }
    }
    return parts.sort((a, b) => a.ts - b.ts)
  }, [model])

  // console.log(model)
  // console.table(parts)

  const handleExpand = (part) => {
    onExpand(part.component)
  }

  return (
    <Box className={classes.root}>
      <Timeline className={classes.timeLineRoot}>
        {parts.map((part, idx) => (
          <TimelineItem
            key={`part-${idx}`}
            className={classes.timelineItm}
            style={{ height: '86px' }}
          >
            <TimelineSeparator>
              <Box className={classNames(classes.timeLineIcon)}>
                {part.icon}
              </Box>
              {idx < parts.length - 1 && (
                <TimelineConnector className={classes.timeLineConnector} />
              )}
            </TimelineSeparator>

            <TimelineContent>
              <Grid
                container
                spacing={2}
                onClick={() => handleExpand(part)}
                style={{ cursor: 'pointer' }}
              >
                <Grid item xs={2}>
                  <Typography
                    className={classNames(classes.center, classes.partTitle)}
                    style={{
                      textDecoration: part.cancelled ? 'line-through' : 'none',
                    }}
                  >
                    {moment(part.ts).format('D')}
                  </Typography>
                  <Typography
                    className={classNames(classes.center, classes.partSubtitle)}
                  >
                    {_.capitalize(moment(part.ts).format('MMM'))}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography noWrap className={classes.partTitle}>
                    {part.titles?.map((title, titleIdx) => (
                      <>
                        <span
                          key={`sub-${titleIdx}`}
                          style={{
                            textDecoration: part.cancelled
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          {title}
                        </span>
                        {titleIdx < part.titles.length - 1 && (
                          <ArrowForward
                            style={{
                              height: '16px',
                              marginLeft: '4px',
                              marginRight: '4px',
                              display: 'inline',
                              position: 'relative',
                              top: '2px',
                            }}
                          />
                        )}
                      </>
                    ))}
                    {part.cancelled && (
                      <Typography
                        className={classes.cancelledText}
                        style={{ fontSize: '12px' }}
                      >
                        {t('cancelled')}
                      </Typography>
                    )}
                  </Typography>
                  <Typography className={classes.partSubtitle}>
                    {part.subtitles?.map((subTitle, subIdx) => (
                      <>
                        <span key={`sub-${subIdx}`}>{subTitle}</span>
                        {subIdx < part.subtitles.length - 1 && (
                          <Box className={classes.dot} />
                        )}
                      </>
                    ))}
                  </Typography>
                </Grid>
                {onExpand && (
                  <Grid item xs={1} className={classes.expandBtnContainer}>
                    <KeyboardArrowRight color="primary" />
                  </Grid>
                )}
              </Grid>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  )
}

export default memo(TripDetails)
