import React, { useEffect, Fragment, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Flightfilter from '../../containers/flights/filters/flightfilters'
import FlightSelectedTrip from '../../containers/flights/flightSelectedTrip'
import FlightSearchContainer from '../../containers/flights/flightSearchContainer'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NoTripsFound from '../../components/general/noTripsFound'
import { flightOutgoingbreadCrumbAction } from '../../store/breadcrumbs/breadcrumbsAction'
import FlightSearchPageForm from '../../components/flights/flightSearchPageForm'

const useStyles = makeStyles(() => ({
  fixedDiv: {
    position: 'sticky',
    top: 177,
    borderRadius: '12px',
  },
}))

const MultiFlight = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const searchParams = useParams()

  const searchError = useSelector((state) => state.flights.searchError)
  const { authType, user } = useSelector((state) => state.auth)
  const authTypes = ['agent', 'viaAgent']
  const isTopRow = !!user?.isBookingLink || authTypes.includes(authType)

  useEffect(() => {
    dispatch(flightOutgoingbreadCrumbAction(pathname))
  }, [dispatch, pathname])

  // const searchParamsForCurrentStop = useMemo(() => {
  //   const { from, to, dep, tripNumber } = searchParams

  //   const currentFrom = from.split('-')[tripNumber]
  //   const currentTo = to.split('-')[tripNumber]
  //   const currentDep = dep.split('-')[tripNumber]

  //   return {
  //     from: currentFrom,
  //     to: currentTo,
  //     dep: currentDep,
  //     adult: searchParams.adult,
  //     child: searchParams.child,
  //     infant: searchParams.infant,
  //     tripNumber,
  //   }
  // }, [searchParams])

  const modifiedParams = { ...searchParams, isOneWay: true }
  return (
    <>
      <FlightSearchPageForm searchParams={modifiedParams} searchType="multi" />
      {searchError ? (
        <NoTripsFound />
      ) : (
        <Box mt={isMobile ? 1 : 3} p={1}>
          <Container maxWidth="lg" disableGutters>
            <Grid
              container
              direction={isMobile ? 'column-reverse' : 'row'}
              spacing={2}
            >
              {isMobile ? (
                ''
              ) : (
                <Grid item xs={12} md={3}>
                  <Flightfilter
                    isMultiStop
                    multiStopId={searchParams.tripNumber}
                  />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <FlightSearchContainer isMulti />
              </Grid>

              {isMobile ? (
                ''
              ) : (
                <Grid item xs={12} md={3}>
                  <Card
                    elevation={0}
                    style={{
                      top: !isTopRow ? '177px' : '232px',
                    }}
                    className={classes.fixedDiv}
                  >
                    <CardContent>
                      <FlightSelectedTrip />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      )}
    </>
  )
}

export default MultiFlight
