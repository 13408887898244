import React, { memo, useEffect, useState } from 'react'
import moment from 'moment'
import { Box, CardMedia, Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import defaultStyles from './defaultTimeline.styles'
import _ from 'lodash'
import {
  openSimpleInfoModal,
  setBookingPartCancelModalData,
} from '../../store/modals/modalAction'
import { useDispatch, useSelector } from 'react-redux'
import {
  canCancelBooking,
  prepareCancellationProhibitedModal,
} from '../../utils/general'
import CustomIconButton from '../reusable/customIconButton'
import { Clear } from '@material-ui/icons'
import TripHeader from './tripHeader'
import { createPortal } from 'react-dom'
import { fetchHotelDescriptiveContents } from '../../repositories/hotel'

const HotelTimeline = ({
  model,
  hotel,
  isMain,
  enableCancel = true,
  price,
  onClose,
  mainRef,
  hotelIndex,
}) => {
  const classes = defaultStyles()
  const startDate = hotel?.startDate
  const endDate = hotel?.endDate
  const title = hotel?.title
  const {
    freeCancellation,
    breakfastIncluded,
    cancellationType,
    bookingReferences,
    checkInTime,
    checkOutTime,
    rooms,
  } = hotel
  const { t } = useTranslation()
  const start = !!startDate ? moment(startDate) : null
  const end = !!endDate ? moment(endDate) : null
  const numberOfNights =
    !!start && !!end && start.isValid() && end.isValid()
      ? moment.duration(end.diff(start)).asDays()
      : 0
  const { user, currency } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)

  const onCancel = async () => {
    const { canCancel, reason } = canCancelBooking(user, model, ['hotel'])
    if (!canCancel) {
      const modalData = prepareCancellationProhibitedModal(reason, model, user)
      return dispatch(openSimpleInfoModal(modalData))
    }
    dispatch(
      setBookingPartCancelModalData({
        type: 'hotel',
        pnr: model?.pnr,
        model: isMain ? model : null,
        item: { ...hotel },
        hotelIndex: hotelIndex,
      })
    )
  }

  useEffect(() => {
    fetchHotelDescriptiveContents(hotel?.hotelCode).then((data) => {
      // console.log(data)
      setImage(data.image)
    })
  }, [])

  const isSeparateCancelDisabled =
    model.netsPaymentId !== null || model.paiwiseCheckoutId

  const isCancelled = !!model && (hotel?.cancelled || model?.cancelled)
  const getCancelButton = () => {
    if (isSeparateCancelDisabled) return null
    if (!model?.pnr) return null
    if (isCancelled) {
      return <Box className={classes.cancelledText}>{t('cancelled')}</Box>
    }
    if (!model?.isUpcoming || !enableCancel) return null
    return (
      <CustomIconButton
        label={`${t('cancel booking')} ${t('hotel').toLowerCase()}`}
        color="red"
        icon={<Clear />}
        onClick={(e) => {
          e.stopPropagation()
          onCancel()
        }}
      />
    )
  }

  return (
    <Box>
      <TripHeader type="hotel" onClose={onClose} />

      <CardMedia
        style={{
          height: '230px',
          width: '100%',
          objectFit: 'cover',
        }}
        image={image || null}
        title={title}
        component="img"
        onError={(e) => {
          console.log(e)
        }}
      />

      <Box className={classes.cardContent}>
        <Typography variant="h6" className={classes.title}>
          {_.startCase((title || 'Unknown hotel').toLowerCase())}
        </Typography>

        <Grid container>
          <Grid item xs={5} className={classes.label}>
            {t('hotel search numbers')}
          </Grid>
          <Grid item xs={7} className={classes.importantValue}>
            {rooms}
          </Grid>
          <Grid item xs={5} className={classes.label}>
            {t('number of nights')}
          </Grid>
          <Grid item xs={7} className={classes.importantValue}>
            {numberOfNights}
          </Grid>
          <Grid item xs={5} className={classes.label}>
            {t('check-in')}
          </Grid>
          <Grid item xs={7} className={classes.importantValue}>
            {checkInTime}
          </Grid>
          <Grid item xs={5} className={classes.label}>
            {t('check-out')}
          </Grid>
          <Grid item xs={7} className={classes.value}>
            {checkOutTime}
          </Grid>
          <Grid item xs={5} className={classes.label}>
            {t('trip address')}
          </Grid>
          <Grid item xs={7} className={classes.value}>
            {hotel?.address}
            {hotel?.city}
          </Grid>
          <Grid item xs={5} className={classes.label}>
            {t('telephone')}
          </Grid>
          <Grid item xs={7} className={classes.value}>
            {hotel?.phone}
          </Grid>
          <Grid item xs={5} className={classes.label}>
            {t('booking number')}
          </Grid>
          <Grid item xs={7} className={classes.value}>
            {(typeof bookingReferences == 'string'
              ? bookingReferences
              : bookingReferences?.join(', ')) || '-'}
          </Grid>
        </Grid>

        <Box mt={3} mb={3}>
          <Divider />
        </Box>

        <Typography variant="h6" className={classes.title}>
          {t('room details')}
        </Typography>

        <Typography variant="h6" className={classes.subTitle}>
          {t('hotel search room')}
        </Typography>

        {hotel.roomTitle && (
          <Box className={classes.label}>{hotel.roomTitle}</Box>
        )}

        <Typography variant="h6" className={classes.subTitle}>
          {t('details')}
        </Typography>

        {cancellationType === 'free' && (
          <Box className={classes.label}>
            {t('hotel popular free') +
              (freeCancellation
                ? ' ' + t('until').toLowerCase() + ' ' + freeCancellation
                : '')}
          </Box>
        )}

        <Box className={classes.label}>
          {t(
            breakfastIncluded === '1' || hotel.breakfastIncluded
              ? 'breakfast included'
              : 'breakfast not included'
          )}
        </Box>

        <Box className={classes.label}>
          {price || hotel.price} {currency || 'SEK'}
        </Box>
      </Box>

      <Box style={{ marginBottom: '100px' }} />

      {createPortal(
        <Box className={classes.footer}>
          <Divider mb={2} />
          {getCancelButton()}
        </Box>,
        mainRef.current
      )}
    </Box>
  )
}

export default memo(HotelTimeline)
