import React, {
  memo,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles } from '@material-ui/core/styles'
import {
  useMediaQuery,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Container,
  CircularProgress,
  IconButton,
  useTheme,
} from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import FlightIcon from '@material-ui/icons/Flight'
import RailIcon from '@material-ui/icons/DirectionsRailway'
import HotelIcon from '@material-ui/icons/Hotel'
import TransferIcon from '@material-ui/icons/LocalTaxi'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import FetchMyTrips from '../../repositories/my-trips'
import TripSideDrawer from '../../containers/myTrips/tripSideDrawer'
import TripCalendarFiltersModal from '../../containers/modals/tripCalendarFiltersModal'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
const tripsRepository = new FetchMyTrips()

let nameTimeout = null

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    padding: '37px 25px',
    marginTop: '37px',
    borderRadius: '8px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
      marginTop: '0',
      padding: '37px 10px',
    },
  },
  tripBlock: {
    '&:last-child': {
      lineHeight: 0,
    },
  },
  filtersBtn: {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '18px',
    minWidth: '124px',
    minHeight: '36px',
    border: `1px solid ${theme.palette.border}`,
    paddingRight: '11px',
    marginLeft: '13px',

    '& > span': {
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightSemiBold,
      color: theme.palette.common.black,
      flex: '1',
      textAlign: 'center',
    },

    '& > svg': {
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
    },
  },
  userNameInnerBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '14px',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.common.black,

    '& > svg': {
      color: 'silver',
      marginRight: theme.spacing(2),
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
  nameFilterField: {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',

      '& input': {
        paddingTop: '11px',
        paddingBottom: '11px',
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    },
  },
  filterFieldsBlock: {
    width: '100%',
  },
  textFiltersBlock: {
    marginBottom: '32px',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
      paddingLeft: '13px',
    },
  },
  checkboxRow: {
    marginBottom: '32px',

    '& .MuiFormControlLabel-root .MuiCheckbox-root': {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  loadingFoglight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '8px',
    backgroundColor: '#00000069',
    zIndex: '4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '30px',
  },
  pageHeader: {
    color: theme.palette.common.black,
    fontSize: '32px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'normal',
    marginLeft: '0',
    paddingBottom: '25px',
    marginBottom: '25px',
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  tableOuter: {
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
  },
  basicCell: {
    borderLeft: `1px solid ${theme.palette.border}`,
    borderBottom: `1px solid ${theme.palette.border}`,
    padding: '12px 10px',
    backgroundColor: 'white',
    textAlign: 'center',

    '&:last-child': {
      borderRight: `1px solid ${theme.palette.border}`,
    },

    '&.newMonthCell': {
      borderLeft: `2px solid ${theme.palette.indicatorGrey}`,
    },
  },
  icon: {
    color: theme.palette.primary.main,

    '&.flightIcon': {
      transform: 'rotate(90deg)',
    },
  },
  filterBtnCell: {
    borderBottom: '1px solid white',
    backgroundColor: 'white',
    textAlign: 'left',
  },
  fColCell: {
    width: '300px',
    minWidth: '300px',
    position: 'sticky',
    left: 0,
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      width: '200px',
      minWidth: '200px',
    },
  },
  headerCell: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: '#999999',
    textAlign: 'center',
    minWidth: '56px',

    [theme.breakpoints.down('sm')]: {
      minWidth: '44px',
    },
  },
  weekEndCell: {
    backgroundColor: 'rgba(216, 216, 216, 0.5)',
  },
  todayCell: {
    backgroundColor: '#13c502',
    color: 'white',
  },
  loadingProgress: {
    marginLeft: '10px',
  },
  mainHeaderCell: {
    borderLeft: `2px solid ${theme.palette.indicatorGrey}`,
    borderBottom: `1px solid ${theme.palette.border}`,
    padding: '12px 10px',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    position: 'sticky',
    backgroundColor: 'white',
    left: '300px',

    [theme.breakpoints.down('sm')]: {
      left: '200px',
    },
  },
  paginationCell: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.border}`,
    textAlign: 'center',
    verticalAlign: 'middle',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },

  paginateText: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.common.black,
  },
}))

const bookingIcons = {
  flight: FlightIcon,
  hotel: HotelIcon,
  rail: RailIcon,
  transfer: TransferIcon,
  car: DirectionsCarIcon,
  mixed: FlightIcon,
}

let prevScrollX = null
let prevScrollW = null
const TripsCalendars = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  // date loading range
  // eslint-disable-next-line
  const [step, setStep] = useState(30)
  const [selectedTrip, setSelectedTrip] = useState(null)
  const [tripLoading, setTripLoading] = useState(null)
  const [FModalOpened, setFModalOpened] = useState(false)
  const [iniScroll, setIniScroll] = useState(false)
  const scrollRef = useRef(null)
  const currDateRef = useRef(null)
  const [showDates, setShowDates] = useState({
    unixFrom: null,
    unixTo: null,
  })
  const [filters, setFilters] = useState({
    withBookings: false,
    withUpcomingBookings: false,
    userName: '',
    withInactive: false,
  })
  const [mainState, setMainState] = useState({
    loading: false,
    page: 1,
    limit: 25,
    loadedPage: 0,
    calendarData: [],
    lastLoaded: null,
    pages: 1,
    dates: { dateFrom: null, dateTo: null },
  })
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isLoading: userTripsLoading } = useSelector((state) => state.myTrips)
  const { favoriteUsers, authType } = useSelector((state) => state.auth)

  const minHeight = useMemo(() => {
    if (mainState.limit >= 50) {
      return 4000
    } else if (mainState.limit >= 25) {
      return 2000
    } else {
      return 1000
    }
  }, [mainState.limit])

  const moveNextPage = () => {
    if (mainState.page < mainState.pages)
      setMainState({ ...mainState, page: mainState.page + 1 })
  }

  const movePrevPage = () => {
    if (mainState.page > 1)
      setMainState({ ...mainState, page: mainState.page - 1 })
  }

  const delayedStateUpdate = useCallback(
    (data) => {
      if (!showDates.unixTo || !showDates.unixFrom) {
        setShowDates({
          dateFrom: new moment().add(-180, 'days').format('MM-DD-YYYY'),
          dateTo: new moment().add(180, 'days').format('MM-DD-YYYY'),
        })
      }
      setTimeout(() => setMainState({ ...mainState, ...data }), 1)
    },
    [mainState, showDates]
  )

  const iniScrollToCurrent = useCallback(() => {
    if (scrollRef?.current && currDateRef?.current) {
      scrollRef.current.scrollLeft =
        currDateRef.current.offsetLeft - scrollRef.current.clientWidth / 2
      setIniScroll(true)
    }
  }, [])

  const getCalendarData = useCallback(async () => {
    setMainState({ ...mainState, loading: true, calendarData: [] })
    const newState = {}

    try {
      const currDate = new moment().format('MM-DD-YYYY')
      const dateFrom = moment(currDate, 'MM-DD-YYYY')
        .add(-1 * step, 'days')
        .format('MM-DD-YYYY')
      const dateTo = moment(currDate, 'MM-DD-YYYY')
        .add(step, 'days')
        .format('MM-DD-YYYY')
      newState['dates'] = { dateFrom, dateTo }
      const rqFilters = {
        page: mainState.page,
        limit: mainState.limit,
        dateFrom,
        dateTo,
        ...filters,
        authType,
      }
      const data = await tripsRepository.getByUsersAndDate(rqFilters)
      const { allUsers, pages } = data
      if (favoriteUsers?.length > 0) {
        allUsers.sort((a, b) => {
          const aIsFav = favoriteUsers?.includes(a.uniqueId)
          const bIsFav = favoriteUsers?.includes(b.uniqueId)
          if (aIsFav && !bIsFav) return -1
          if (bIsFav && !aIsFav) return 1
          return a.fullName?.localeCompare(b.fullName, 'sv', {
            sensitivity: 'base',
          })
        })
      }
      newState['calendarData'] = [...allUsers]
      newState['pages'] = [pages]
    } catch (e) {
    } finally {
      newState['loadedPage'] = mainState.page
      newState['loading'] = false
      newState['lastLoaded'] = null
      prevScrollX = null
      delayedStateUpdate(newState)
      setIniScroll(false)
    }
  }, [mainState, step, delayedStateUpdate, filters])

  const setFilter = (newFilters = {}) => {
    setFilters({ ...filters, ...newFilters })
  }

  const onChangeModal = (filters = {}) => {
    setFModalOpened(false)
    setFilter(filters)
  }

  const checkboxChange = (e) =>
    setFilter({ [e.target.name]: !!e.target.checked })

  const nameFilterChanged = (e) => {
    const val = e.target.value

    if (!!nameTimeout) {
      clearTimeout(nameTimeout)
      nameTimeout = null
    }

    nameTimeout = setTimeout(() => {
      setFilter({ userName: val })
    }, 700)
  }

  const handleChangeActive = useCallback((model) => {
    const value = typeof model?.pnr === 'string' ? model : null
    setSelectedTrip(value)
  }, [])

  const getTrip = useCallback(async (pnr) => {
    return await tripsRepository.fetchBookings(
      { pnr },
      'fetch-exact-calendar-booking'
    )
  }, [])

  const tripClicked = useCallback(
    async (pnr) => {
      setTripLoading(true)
      try {
        const tripData = await getTrip(pnr)
        handleChangeActive(tripData?.[0] || null)
      } catch (e) {
        handleChangeActive(null)
      } finally {
        setTripLoading(false)
      }
    },
    [getTrip, handleChangeActive]
  )

  const datePaginate = useCallback(
    async (dir = 'from') => {
      const { dates, calendarData } = mainState
      if (!dates?.dateTo || !dates?.dateFrom || !calendarData?.length)
        return false
      const users = []
      let dateFrom = null
      let dateTo = null
      calendarData.forEach((cd) => {
        if (!users.includes(cd.uniqueId)) users.push(cd.uniqueId)
      })
      if (!users?.length) return false

      const datesToUpdate = { ...dates }
      if (dir === 'from') {
        dateFrom = moment(dates.dateFrom, 'MM-DD-YYYY')
          .add(-1 * step, 'days')
          .format('MM-DD-YYYY')
        dateTo = dates.dateFrom
        datesToUpdate['dateFrom'] = dateFrom
      } else if (dir === 'to') {
        dateFrom = dates.dateTo
        dateTo = moment(dates.dateTo, 'MM-DD-YYYY')
          .add(step, 'days')
          .format('MM-DD-YYYY')
        datesToUpdate['dateTo'] = dateTo
      }

      if (!dateFrom || !dateTo) return false

      const newState = {}
      try {
        setMainState({ ...mainState, loading: true })

        await new Promise((resolve) => setTimeout(resolve, 700))
        const rqFilters = {
          page: null,
          dateFrom,
          dateTo,
          usersID: users,
          groupByUID: true,
          ...filters,
          authType,
        }
        const data = await tripsRepository.getByUsersAndDate(rqFilters)
        const newCalendarData = calendarData.map((cd) => {
          const tmp = { ...cd }
          if (!data?.[cd.uniqueId]?.bookings?.length) return tmp
          const tmpBookings = data[cd.uniqueId].bookings
          tmp.bookings =
            dir === 'from'
              ? [...tmpBookings, ...(tmp.bookings || [])]
              : [...(tmp.bookings || []), ...tmpBookings]
          return tmp
        })

        newState['dates'] = datesToUpdate
        newState['calendarData'] = newCalendarData
        newState['lastLoaded'] = dir
      } catch (e) {
      } finally {
        newState['loading'] = false
        delayedStateUpdate(newState)
      }
    },
    [mainState, step, delayedStateUpdate, filters]
  )

  const fixScroll = useCallback(() => {
    if (!scrollRef?.current?.scrollWidth) return
    let tmpPrev = prevScrollW
    prevScrollW = scrollRef.current.scrollWidth

    if (
      scrollRef.current.scrollWidth > tmpPrev &&
      mainState.lastLoaded === 'from'
    ) {
      scrollRef.current.scrollLeft =
        scrollRef.current.scrollLeft + (scrollRef.current.scrollWidth - tmpPrev)
    }
  }, [mainState.lastLoaded])

  useEffect(
    () => {
      fixScroll()
      if (!iniScroll) iniScrollToCurrent()
      if (!mainState.loading && mainState.loadedPage !== mainState.page)
        getCalendarData()
    },
    // eslint-disable-next-line
    [iniScroll, mainState.loadedPage, mainState.page, mainState.loading]
  )

  useEffect(
    () => {
      if (!mainState.loading) getCalendarData()
    },
    // eslint-disable-next-line
    [filters]
  )

  /**
   * Reload selected PNR after cancellation.
   */
  useEffect(() => {
    if (!userTripsLoading && selectedTrip) {
      getTrip(selectedTrip.pnr).then((tripData) => {
        handleChangeActive(tripData?.[0] || null)
      })
    }
  }, [userTripsLoading])

  const getDateKeys = useMemo(() => {
    const format = 'MM-DD-YYYY'
    const keys = []
    if (!mainState?.dates?.dateTo || !mainState?.dates?.dateFrom) return []
    const currDate = moment(mainState.dates.dateFrom, format)
    const endDate = moment(mainState.dates.dateTo, format)
    do {
      const full = currDate.format(format)
      const day = currDate.format('D')
      const weekDay = currDate.format('ddd').toUpperCase()
      const mainHead = currDate.format('MMM YYYY')
      keys.push({ full, day, mainHead, weekDay })
    } while (currDate.add(1, 'days').diff(endDate) < 0)
    return keys
  }, [mainState.dates])

  const onScroll = useCallback(
    (e) => {
      const tmpPrev = prevScrollX
      const el = e.target
      prevScrollX = el.scrollLeft
      const leftPos = 0

      let rightPos = el.scrollWidth - el.clientWidth

      const tillRight = rightPos - el.scrollLeft
      const tillLeft = el.scrollLeft - leftPos
      if (tmpPrev === null || tmpPrev === el.scrollLeft) return

      const isLoadLeft = tillLeft < 600 && tmpPrev > el.scrollLeft
      const isLoadRight = tillRight < 600 && tmpPrev < el.scrollLeft

      if ((isLoadLeft || isLoadRight) && !mainState.loading) {
        datePaginate(isLoadLeft ? 'from' : 'to')
      }
    },
    [mainState.loading, datePaginate]
  )

  const sortTrips = (trips) => {
    const results = {}
    const addedKeys = []

    trips.forEach((t) => {
      // write outbound trip
      let dKey = (t.outboundDepartDate || '') + `-${t.pnr}`
      if (t.outboundDepartDate && !addedKeys.includes(dKey)) {
        addedKeys.push(dKey)
        if (!results?.[t.outboundDepartDate]?.length)
          results[t.outboundDepartDate] = []
        results[t.outboundDepartDate].push({ ...t, dirKey: 'outbound' })
      }

      // write return trip
      dKey = (t.inboundDepartDate || '') + `-${t.pnr}`
      if (t.inboundDepartDate && !addedKeys.includes(dKey)) {
        addedKeys.push(dKey)
        if (!results?.[t.inboundDepartDate]?.length)
          results[t.inboundDepartDate] = []
        results[t.inboundDepartDate].push({ ...t, dirKey: 'return' })
      }
    })
    return results
  }

  const buildTableData = useMemo(
    () => {
      const keys = getDateKeys

      const heads = []
      const mainHeadsData = []
      const mainHeads = []
      const currDate = new moment().format('MM-DD-YYYY')
      const weekEndDays = ['SAT', 'SUN']

      let prevHMonth = null
      keys.forEach((k) => {
        let addClass = ''
        const isCurrent = k.full === currDate

        if (isCurrent) {
          addClass = classes.todayCell
        } else if (weekEndDays.includes(k.weekDay)) {
          addClass = classes.weekEndCell
        }

        if (!!prevHMonth && prevHMonth !== k.mainHead)
          addClass += ' newMonthCell'
        prevHMonth = k.mainHead
        let tmpRef = null
        if (isCurrent) {
          tmpRef = currDateRef
        }
        heads.push(
          <td
            ref={tmpRef}
            key={`${k.full}-head-cell`}
            className={`${classes.basicCell} ${classes.headerCell} ${addClass}`}
          >
            <Box key={'head-cell-day'}>{k.day}</Box>
            <Box key={'head-cell-week'}>{k.weekDay}</Box>
          </td>
        )
        const mhLength = mainHeadsData.length
        if (
          mhLength <= 0 ||
          mainHeadsData[mhLength - 1].mainHead !== k.mainHead
        ) {
          mainHeadsData.push({ mainHead: k.mainHead, colSpan: 1, key: k.full })
        } else {
          mainHeadsData[mhLength - 1].colSpan++
        }
      })

      mainHeadsData.forEach((h) => {
        mainHeads.push(
          <td
            key={`${h.mainHead}-mhead`}
            className={classes.mainHeaderCell}
            colSpan={h.colSpan}
          >
            {h.mainHead}
          </td>
        )
      })

      const rows = []

      if (mainState?.calendarData?.length) {
        mainState.calendarData.forEach((cd) => {
          const row = []
          const trips = sortTrips(cd.bookings)
          let prevMonth = null
          row.push(
            <td
              key={`first-name-cell-${cd.uniqueId}`}
              style={{ borderRight: `1px solid ${theme.palette.border}` }}
              className={`${classes.fColCell} ${classes.basicCell}`}
            >
              <div
                className={classes.userNameInnerBlock}
                key={'first-name-cell-inner'}
              >
                <AccountCircleIcon key={'account-table-icon'} />
                <span key={'cell-span'}>
                  {cd.firstName} {cd.lastName}
                </span>
              </div>
            </td>
          )

          keys.forEach((k) => {
            let addCellClass = weekEndDays.includes(k.weekDay)
              ? classes.weekEndCell
              : ''
            if (!!prevMonth && prevMonth !== k.mainHead)
              addCellClass += ' newMonthCell'
            prevMonth = k.mainHead
            const bookings = trips?.[k.full]
            let cellContent = []
            if (bookings?.length) {
              bookings.forEach((t) => {
                const tTypes = []
                tTypes.push(t.type)

                if (t?.rentalCar && !tTypes.includes('car')) tTypes.push('car')
                if (t?.hotel && !tTypes.includes('hotel')) tTypes.push('hotel')

                if (t?.transfers?.length) {
                  const dirTransfer = t?.transfers?.find(
                    (sTrans) =>
                      !!sTrans?.dirKey && sTrans.dirKey.search(t.dirKey) >= 0
                  )
                  if (dirTransfer && !tTypes.includes('transfer'))
                    tTypes.push('transfer')
                }
                const tmpContent = tTypes.map((type) => ({
                  type,
                  Icon: bookingIcons[type],
                  pnr: t.pnr,
                  isInactive: t.isInactive,
                }))
                cellContent = [...cellContent, ...tmpContent]
              })
            }

            row.push(
              <td
                key={`data-cell-${k.full}-${cd.uniqueId}`}
                className={`${classes.basicCell} ${addCellClass}`}
              >
                {cellContent.map((cc) => {
                  const Icon = cc.Icon
                  const type = cc.type
                  return (
                    <Box
                      key={`trip-icon-${type}-${cc.pnr}`}
                      className={classes.tripBlock}
                      onClick={() => tripClicked(cc.pnr)}
                    >
                      {!!Icon && (
                        <Icon
                          className={`${classes.icon} ${type + 'Icon'}`}
                          style={{
                            color: cc.isInactive
                              ? theme.palette.text.disabled
                              : theme.palette.primary.main,
                          }}
                        />
                      )}
                      {!Icon && type}
                    </Box>
                  )
                })}
              </td>
            )
          })
          rows.push(<tr key={`user-data-row-${cd.uniqueId}`}>{row}</tr>)
        })
      }

      return { mainHeads, heads, rows }
    },
    // eslint-disable-next-line
    [getDateKeys, mainState.calendarData, mainState.dates, classes]
  )

  return (
    <Container
      key={'user-orders-calendar-container'}
      maxWidth="lg"
      disableGutters
    >
      <Box
        key={'user-orders-calendar-container-inner'}
        className={classes.root}
        style={{ minHeight }}
      >
        {tripLoading && (
          <Box key={'loading-foglight'} className={classes.loadingFoglight}>
            <CircularProgress size={60} thickness={4} />
          </Box>
        )}
        <Box
          key={'user-orders-calendar-container-header'}
          className={classes.pageHeader}
        >
          {t('booking calendar')}
          {!!mainState.loading && (
            <CircularProgress
              key={'loadin-mark'}
              size={20}
              thickness={5}
              className={classes.loadingProgress}
            />
          )}
        </Box>
        <Box key={'calendar-filter-form-block'}>
          <FormControl
            key={'calendar-filter-form-'}
            component="fieldset"
            className={classes.filterFieldsBlock}
          >
            <FormGroup key={'calendar-bookings-filter-block-1'} row>
              <Grid
                item
                className={classes.textFiltersBlock}
                key={'calendar-bookings-filter-block-1-inner'}
                xs={12}
              >
                <Grid key={'calendar-name-filter-block'} item xs={12} lg={6}>
                  <TextField
                    key={'calendar-name-filter-field'}
                    name="userName"
                    onChange={nameFilterChanged}
                    variant="outlined"
                    placeholder={t('find travelers')}
                    className={classes.nameFilterField}
                  />
                </Grid>
                <Grid
                  key={'calendar-policy-filter-block'}
                  item
                  xs={12}
                  lg={6}
                />
              </Grid>
            </FormGroup>
            {!isMobile && (
              <FormGroup
                key={'calendar-bookings-filter-block-2'}
                className={classes.checkboxRow}
                row
              >
                <FormControlLabel
                  key={'withUpcomingBookings-filter'}
                  control={
                    <Checkbox
                      checked={filters?.withUpcomingBookings || false}
                      color="primary"
                      name="withUpcomingBookings"
                      onChange={checkboxChange}
                    />
                  }
                  label={t('travelers with upcoming bookings')}
                  labelPlacement="end"
                />

                <FormControlLabel
                  key={'withBookings-filter'}
                  control={
                    <Checkbox
                      checked={filters?.withInactive || false}
                      color="primary"
                      name="withInactive"
                      onChange={checkboxChange}
                    />
                  }
                  label={t('include past and cancelled')}
                  labelPlacement="end"
                />
              </FormGroup>
            )}
          </FormControl>
        </Box>
        <Box
          key={'user-orders-calendar-table-scroll-container'}
          ref={scrollRef}
          onScroll={onScroll}
          className={classes.tableOuter}
        >
          {!!buildTableData?.heads?.length && (
            <table
              cellSpacing={0}
              cellPadding={0}
              key={'user-orders-calendar-table'}
            >
              <tbody key={'user-orders-calendar-body'}>
                <tr key={'mainHeadsRow'}>
                  <td
                    key={'first-row-top-cell'}
                    className={`${classes.fColCell} ${classes.filterBtnCell}`}
                  >
                    <Box
                      key={'filters-button'}
                      className={classes.filtersBtn}
                      onClick={() => setFModalOpened(true)}
                    >
                      <span key={'filters-button-text'}>{t('filters')}</span>
                      <KeyboardArrowDownIcon key={'filters-button-icon'} />
                    </Box>
                  </td>
                  {buildTableData.mainHeads}
                </tr>
                <tr key={'dateHeadsRow'}>
                  <td
                    key={'first-row-bottom-cell'}
                    className={`${classes.fColCell} ${classes.paginationCell}`}
                  >
                    <Box key={'navigation-block'}>
                      <IconButton
                        key={'page-back-btn'}
                        onClick={movePrevPage}
                        disabled={mainState.page < 2 || mainState.loading}
                      >
                        <NavigateBeforeIcon key={'page-back-btn-icon'} />
                      </IconButton>
                      <span
                        key={'pagination-text'}
                        className={classes.paginateText}
                      >
                        {mainState.page} / {mainState.pages}
                      </span>
                      <IconButton
                        key={'page-next-btn'}
                        onClick={moveNextPage}
                        disabled={
                          mainState.page >= mainState.pages || mainState.loading
                        }
                      >
                        <NavigateNextIcon key={'page-next-btn-icon'} />
                      </IconButton>
                    </Box>
                  </td>
                  {buildTableData.heads}
                </tr>
                {buildTableData.rows}
              </tbody>
            </table>
          )}
        </Box>
      </Box>
      <TripSideDrawer
        model={selectedTrip}
        onChangeActive={handleChangeActive}
        enableCancel={true}
      />
      <TripCalendarFiltersModal
        key={'calendar-filters-modal'}
        modalBoolean={FModalOpened}
        onClose={() => setFModalOpened(false)}
        iniFilters={filters}
        onChange={onChangeModal}
      />
    </Container>
  )
}

export default memo(TripsCalendars)
