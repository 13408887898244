import React, { Fragment, memo, useCallback, useMemo } from 'react'
import { Typography, Box, Divider } from '@material-ui/core'
import SelectedBagageItem from './selectBagageItem'
import BaggageChips from './BaggageChips'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const SelectBagage = ({
  catalogue,
  fareFamily,
  onSelect,
  selected,
  outgoing,
  returning,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { passengers } = useSelector((state) => state.flights)
  const { standAloneLoading } = useSelector((state) => state.flightDetails)

  const { generalBags, carryOnBags } = useMemo(() => {
    const currentBags = catalogue?.length ? catalogue : []
    const carryOnDescr = ['carry-on baggage']
    const generalDescr = ['additional checked bag']

    const carryOnBags = currentBags.filter((b) => {
      return carryOnDescr.includes(b?.description)
    })
    const generalBags = currentBags.filter((b) => {
      return generalDescr.includes(b?.description)
    })

    return { carryOnBags, generalBags }
  }, [catalogue])

  const { t } = useTranslation()

  const baggageChanged = useCallback(
    (userId, bag, type) => {
      onSelect(userId, bag, type)
    },
    [onSelect]
  )

  const generalOnChanged = useCallback(
    (userId, bag) => {
      baggageChanged(userId, bag, 'general')
    },
    [baggageChanged]
  )

  const carryOnChanged = useCallback(
    (userId, bag) => {
      baggageChanged(userId, bag, 'carryOn')
    },
    [baggageChanged]
  )

  const selectBagagge = passengers.map((passenger, i) => {
    return (
      <Box key={`passenger-bags-${i}`} pt={2} pb={4}>
        <Typography className={classes.weight}>
          {passenger.firstName} {passenger.lastName}
        </Typography>
        <BaggageChips
          fareFamily={fareFamily}
          outgoing={outgoing}
          returning={returning}
        />
        {standAloneLoading && (
          <Box textAlign="center" pt={3}>
            <CircularProgress />
          </Box>
        )}
        <SelectedBagageItem
          userId={passenger.uniqueId}
          outgoing={outgoing}
          returning={returning}
          selectedBag={selected?.[passenger.uniqueId]?.['carryOn']}
          onChange={carryOnChanged}
          fareFamily={fareFamily}
          bags={carryOnBags}
          key={`carry-on-bags-${i}`}
          passenger={passenger}
          title={t('add carry-on baggage')}
        />

        <SelectedBagageItem
          userId={passenger.uniqueId}
          outgoing={outgoing}
          returning={returning}
          selectedBag={selected?.[passenger.uniqueId]?.['general']}
          onChange={generalOnChanged}
          fareFamily={fareFamily}
          bags={generalBags}
          key={`general-bags-${i}`}
          passenger={passenger}
        />
      </Box>
    )
  })

  return (
    <>
      <Box pt={4} paddingX={isMobile ? 2 : 4} pb={4}>
        <Box pb={3} pt={isMobile ? 2 : ''}>
          <Box mb={3}>
            <Typography variant="h5" className={classes.weight}>
              {t('select baggage')}
            </Typography>
          </Box>
          <Divider />
        </Box>
        {selectBagagge}
      </Box>
      <Divider />
    </>
  )
}

export default memo(SelectBagage)
