import React, { Fragment, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Formik, useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'
import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  useTheme,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import {
  getUserAction,
  updatePasswordAction,
  updateUserAction,
} from '../../store/auth/authAction'
import { useTranslation } from 'react-i18next'
import { showSnackbarAction } from '../../store/snackbars/snackbarAction'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import SelectUserDOB from './reusable/selectUserDOB'
import * as Yup from 'yup'
import { getPhoneNumberFormat, validatePhoneNumber } from '../../utils/general'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import i18n from 'i18next'
import { getPassengersData } from '../../store/checkout/checkoutActions'

const fieldBorderStyles = {
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
}

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  mobileHeading: {
    fontSize: theme.spacing(1.875),
    fontWeight: theme.typography.fontWeightBold,
  },
  medium: {
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.spacing(0.75),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(0.75),
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '& fieldset': { ...fieldBorderStyles },
    '& .MuiInputBase-input.Mui-disabled': {
      padding: '18.5px 14px',
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      color: 'rgba(0, 0, 0, 0.38)',
      borderRadius: '6px',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiOutlinedInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '&.Mui-disabled .MuiSelect-icon': {
      color: '#333',
    },
  },
  inputHeading: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 0.6,
  },
  wrapper: {
    marginBottom: theme.spacing(5),
  },
  modalWrapper: {
    position: 'relative',
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(4.5),
  },
  cancelBtn: {
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
  },
  saveBtn: {
    marginLeft: theme.spacing(6),
    padding: theme.spacing(1, 5),
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  cancelBtnMobile: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    textTransform: 'capitalize',
  },
  saveBtnMobile: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.2, 2.8),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
  },
  smallWrapperLeft: {
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(5),
  },
  smallWrapperRight: {
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(5),
  },
  middleWrapper: {
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(5),
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: 'solid 1px #e6e6e6',
    backgroundColor: 'white',
    zIndex: '1000',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
  },
  datePicker: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  primaryCheckBox: {
    marginBottom: 24,
    marginLeft: 20,
  },
  removeBtn: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    lineHeight: 'normal',
    padding: '8px 40px',
    height: '100%',
  },
}))

const ModalEditInfo = ({ rows }) => {
  const { user } = useSelector((state) => state.auth)
  const splitAndLast = (el) => {
    return el.key.split('.')[el.key.split('.').length - 1]
  }

  const createInitialState = () => {
    return rows.rows.reduce((acc, el) => {
      const name = splitAndLast(el)
      const fields = { ...acc, [name]: _.get(rows.values, el.key) || '' }
      if (name === 'phone') fields.isPhone = true
      if (name === 'mobile' && user?.umbrellaEnabled)
        fields.isUmbrellaMobile = true
      if (name === 'password') fields.isPassword = true
      if (name === 'issuePlace')
        fields.isUmbrellaEnabled = user?.umbrellaEnabled
      if (name === 'birthdate') {
        const momentDate = moment(rows?.values?.birthdate, 'YYYY-MM-DD')
        let birthDate
        if (!momentDate.isValid()) {
          birthDate = { y: '', m: '', d: '' }
        } else {
          birthDate = {
            y: momentDate.format('YYYY'),
            m: momentDate.format('MM'),
            d: momentDate.format('DD'),
          }
        }
        fields.birthdate = birthDate
      }
      return fields
    }, {})
  }

  const { countriesList } = useSelector((state) => state.other)
  const { countryPhone } = useSelector((state) => state.auth)
  const { passengerData } = useSelector((state) => state.checkout)
  const [isChecked, setIsChecked] = useState(rows?.values?.primary)
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { t } = useTranslation()
  const sectionNotEmpty = useMemo(() => {
    if (!rows?.values) return false
    for (let ind in rows.values) {
      if (!!rows.values[ind]) return true
    }
    return false
  }, [rows])
  const phoneNumberFormat = getPhoneNumberFormat(countryPhone?.code)
  const showRemoveBtn = useMemo(() => {
    if (
      passengerData[user?.uniqueId]?.docExists &&
      rows?.section === 'passport'
    )
      return true
    if (sectionNotEmpty && rows?.section === 'home') return true
    return false
  }, [passengerData, user, sectionNotEmpty])

  const handleChange = () => setIsChecked(!isChecked)

  const closeModal = () => {
    if (rows.section === 'password') {
      history.goBack()
    }
    return dispatch(closeAllModals())
  }

  const renderCountriesField = (selectedVal, name, handleChange) => {
    let isExists = false
    const trimVal = selectedVal?.trim() || ''
    const opts = countriesList.map((c) => {
      if (trimVal !== '' && c.code === trimVal) isExists = true
      return <option value={c.code}>{c.name}</option>
    })

    if (!isExists && trimVal !== '') {
      opts.push(<option value={selectedVal}>{selectedVal}</option>)
    }

    return (
      <Select
        native
        value={trimVal}
        onChange={handleChange}
        name={name}
        variant="outlined"
        className={classes.medium}
        InputProps={{ style: { fontWeight: 500 } }}
      >
        <option value={''}> </option>
        {opts}
      </Select>
    )
  }

  const updateInfo = async (values) => {
    closeModal()
    const { phone, mobile, email, alt1, alt2 } = values
    let data = {}

    switch (rows.section) {
      case 'contacts':
        data = { phones: { phone, mobile }, emails: { email, alt1, alt2 } }
        break
      case 'more':
        for (let vkey in values) {
          if (vkey === 'birthdate') {
            if (!data?.customerGeneral) data.customerGeneral = {}
            const tmpDate = new moment(
              `${values[vkey].y}-${values[vkey].m}-${values[vkey].d}`,
              'YYYY-MM-DD'
            )
            const birthDate = tmpDate.isValid()
              ? tmpDate.format('YYYY-MM-DD')
              : ''
            data.customerGeneral.birthDate = birthDate

            if (rows.passport) {
              rows.passport.birthdate = birthDate
              data.passport = rows?.passport
            }
            continue
          }
          data[vkey] = values[vkey]
        }
        break
      case 'passport':
        const tmpDate = new moment(
          `${values['birthdate'].y}-${values['birthdate'].m}-${values['birthdate'].d}`,
          'YYYY-MM-DD'
        )
        const birthdate = tmpDate.isValid() ? tmpDate.format('YYYY-MM-DD') : ''
        if (user?.umbrellaEnabled) {
          data = {
            ...values,
            index: rows?.index,
            primary: isChecked,
            birthdate,
          }
        } else {
          data = { ...values, birthdate }
        }
        break
      default:
        data = { ...values }
        break
    }
    try {
      await dispatch(
        updateUserAction({
          section: rows.section,
          updateData: {
            ...data,
          },
        })
      )
      await dispatch(getUserAction())
      await dispatch(getPassengersData([user?.uniqueId]))
      dispatch(showSnackbarAction('success', t('update success')))
    } catch (e) {
      dispatch(showSnackbarAction('error', t('update error')))
    }
  }

  const changePassword = async (values) => {
    try {
      await dispatch(updatePasswordAction(values))
      dispatch(showSnackbarAction('success', t('update success')))
      closeModal()
    } catch (e) {
      dispatch(showSnackbarAction('error', t('update error')))
    }
  }

  const buildTextField = (row, onChange, values, formik) => {
    const name = splitAndLast(row)
    const error = formik?.touched[name] && formik?.errors[name]
    let gridSize
    if (user?.umbrellaEnabled) {
      gridSize =
        !isDesktop && row.order ? (row.small ? 5 : row.extraSmall ? 3 : 6) : 12
    } else {
      gridSize =
        !isDesktop && row.order ? (row.small ? 4 : row.extraSmall ? 3 : 6) : 12
    }

    let renderClass =
      !isDesktop && row.order
        ? row.order === 1
          ? classes.smallWrapperLeft
          : (row.small && row.order === 2) ||
            (row.extraSmall && _.includes([2, 3], row.order))
          ? classes.middleWrapper
          : classes.smallWrapperRight
        : classes.wrapper
    const errKeys = ['phone', 'confirmpassword', 'mobile']
    return (
      <Grid xs={gridSize} className={renderClass} key={row.key}>
        <Typography className={classes.inputHeading}>{row.title}</Typography>
        <Box mt={1}>
          <TextField
            name={name}
            fullWidth
            error={
              (rows.section === 'passport' || errKeys.includes(row.key)) &&
              !!error
            }
            helperText={
              (rows.section === 'passport' || errKeys.includes(row.key)) &&
              error
            }
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            className={classes.medium}
            type={rows.section === 'password' ? 'password' : 'text'}
            value={values[name]}
            InputProps={{ style: { fontWeight: 500 } }}
            inputMode={splitAndLast(row)}
            onChange={onChange}
            style={{ borderRadius: theme.spacing(0.75) }}
            disabled={row.disabled}
          />
        </Box>
      </Grid>
    )
  }

  const buildDateField = (row, onChange, values, formik) => {
    const name = splitAndLast(row)
    const val = values[name]
    if (!val && _.includes(['issueDate', 'expirationDate'], name))
      onChange(name, moment().format('YYYY-MM-DD'))
    const touched = formik?.touched?.[name]
    const errors = formik?.errors?.[name]
    return (
      <Grid
        xs={
          !isDesktop && row.order
            ? row.small
              ? 4
              : row.extraSmall
              ? 3
              : 6
            : 12
        }
        className={
          !isDesktop && row.order
            ? row.order === 1
              ? classes.smallWrapperLeft
              : classes.smallWrapperRight
            : classes.wrapper
        }
        key={row.key}
      >
        <Typography className={classes.inputHeading}>{row.title}</Typography>
        <Box mt={1}>
          {_.includes(['expirationDate', 'issueDate'], name) ? (
            <Fragment>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="YYYY-MM-DD"
                value={
                  val
                    ? moment(val).format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')
                }
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) =>
                  onChange(name, moment(date).format('YYYY-MM-DD'))
                }
                allowKeyboardControl
                disablePast={name === 'expirationDate'}
                invalidDateMessage={t('invalid date')}
                className={classes.datePicker}
              />
            </Fragment>
          ) : (
            <SelectUserDOB
              dob={val}
              onChange={(d) => {
                onChange(name, d)
              }}
              name={name}
              touched={touched}
              errors={errors}
            />
          )}
        </Box>
      </Grid>
    )
  }
  const buildSelectField = (row, onChange, values, formik) => {
    const tmpOpts = row?.options?.length > 0 ? [...row.options] : []

    const name = splitAndLast(row)
    const val = values[name]

    if (val) {
      const currVal = tmpOpts.find((opt) => opt.value === val)
      if (!currVal) tmpOpts.push({ name: '', value: val })
    }
    const error = formik?.touched[name] && formik?.errors[name]
    let gridSize
    if (user?.umbrellaEnabled) {
      gridSize =
        !isDesktop && row.order ? (row.small ? 4 : row.extraSmall ? 2 : 6) : 12
    } else {
      gridSize =
        !isDesktop && row.order ? (row.small ? 4 : row.extraSmall ? 3 : 6) : 12
    }
    return (
      <Grid
        xs={gridSize}
        className={
          !isDesktop && row.order
            ? row.order === 1
              ? classes.smallWrapperLeft
              : classes.smallWrapperRight
            : classes.wrapper
        }
        key={row.key}
      >
        <Typography className={classes.inputHeading}>{row.title}</Typography>
        <Box mt={1}>
          <Select
            className={classes.medium}
            error={rows.section === 'passport' && !!error}
            InputLabelProps={{ shrink: false }}
            name={name}
            variant="outlined"
            fullWidth
            value={val}
            onChange={onChange}
            IconComponent={ExpandMoreIcon}
            displayEmpty
            disabled={row.disabled}
          >
            <MenuItem
              style={{ color: 'silver' }}
              key={`${name}-empty`}
              value=""
            >
              <span style={{ color: '#999999', fontWeight: '500' }}>
                {t('select')}...
              </span>
            </MenuItem>
            {tmpOpts?.length > 0 &&
              tmpOpts.map((o, ind) => (
                <MenuItem key={`opt-key-${ind}`} value={o.value}>
                  {o?.name
                    ? name === 'sex'
                      ? o?.name
                      : `${o.name} (${o.value})`
                    : ''}
                </MenuItem>
              ))}
          </Select>
          {rows.section === 'passport' && error && (
            <span className={classes.errorMessage}>{error}</span>
          )}
        </Box>
      </Grid>
    )
  }

  const buildField = (row, onChange, values, setFieldValue, formik = {}) => {
    if (!row) return null
    const name = splitAndLast(row)
    if (name === 'country') {
      return renderCountriesField(values[name], name, onChange)
    } else if (row.fieldType === 'date') {
      return buildDateField(row, setFieldValue, values, formik)
    } else if (row.fieldType === 'dropdownList') {
      return buildSelectField(row, onChange, values, formik)
    } else {
      return buildTextField(row, onChange, values, formik)
    }
  }
  const renderPrimaryCheckBox = () => (
    <Box className={classes.primaryCheckBox}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              color="primary"
              name={t('primary')}
              onChange={handleChange}
            />
          }
          label={t('primary')}
        />
      </FormGroup>
    </Box>
  )
  const onRemove = async () => {
    closeModal()
    const index = rows?.index
    try {
      let rqData = { section: rows.section }
      switch (rows.section) {
        case 'home':
          rqData.updateData = { address: {} }
          break
        default:
          rqData.updateData = {
            index,
            isRemoved: true,
          }
          break
      }
      await dispatch(updateUserAction(rqData))
      await dispatch(getUserAction())
      await dispatch(getPassengersData([user?.uniqueId]))
      dispatch(showSnackbarAction('success', t('update success')))
    } catch (e) {
      dispatch(showSnackbarAction('error', t('update error')))
    }
  }

  useFormik({
    initialValues: { ...createInitialState() },
    onSubmit: rows.section === 'password' ? changePassword : updateInfo,
  })
  let validationSchema

  if (rows.section === 'passport') {
    validationSchema = Yup.object().shape({
      sex: Yup.string().required(t('required field')),
      firstName: Yup.string().required(t('required field')),
      lastName: Yup.string().required(t('required field')),
      passportNumber: Yup.string().required(t('required field')),
      nationality: Yup.string().required(t('required field')),
      issueCountry: Yup.string().required(t('required field')),
      issuePlace: Yup.string().when('isUmbrellaEnabled', {
        is: true,
        then: (schema) => schema.required(t('required field')),
      }),
      birthdate: Yup.object().shape({
        y: Yup.string().required(i18n.t('required field')),
        m: Yup.string().required(i18n.t('required field')),
        d: Yup.string().required(i18n.t('required field')),
      }),
    })
  } else {
    // const remarkValidations =
    //   rows?.section === 'more' && user?.umbrellaEnabled
    //     ? {
    //         'remarks.UDID-U13-': Yup.string().required('Hello'),
    //       }
    //     : {}

    const birthdateRow = rows?.rows?.find((r) => r?.key === 'birthdate')
    const birthDateValidation =
      rows.section === 'more' && !!birthdateRow
        ? {
            birthdate: Yup.object().shape({
              y: Yup.string().required(i18n.t('required field')),
              m: Yup.string().required(i18n.t('required field')),
              d: Yup.string().required(i18n.t('required field')),
            }),
          }
        : {}
    validationSchema = Yup.object().shape({
      isPhone: Yup.boolean(),
      isUmbrellaMobile: Yup.boolean(),
      isPassword: Yup.boolean(),
      mobile: Yup.string().when('isUmbrellaMobile', {
        is: true,
        then: (schema) =>
          schema
            .required(t('required field'))
            .test(
              'valid-number',
              t('invalid phone number', { phoneNumberFormat }),
              (value) => validatePhoneNumber(value, countryPhone.code)
            ),
      }),
      phone: Yup.string().when('isPhone', {
        is: true,
        then: (schema) =>
          schema.test(
            'valid-number',
            t('invalid phone number', { phoneNumberFormat }),
            (value) => !value || validatePhoneNumber(value, countryPhone.code)
          ),
      }),
      confirmpassword: Yup.string().when('isPassword', {
        is: true,
        then: (schema) =>
          schema.oneOf([Yup.ref('password'), null], t('confirm error match')),
      }),
      ...birthDateValidation,
      // ...remarkValidations,
    })
  }

  return (
    <Box
      className={classes.modalWrapper}
      pt={isDesktop ? 14 : 5}
      pb={5}
      pr={isDesktop ? 2 : 6}
      pl={isDesktop ? 2 : 6}
    >
      {user?.umbrellaEnabled &&
      user?.docId?.length > 1 &&
      rows.section === 'passport' ? (
        <Box display="flex" alignItems="center">
          {!isDesktop && (
            <Typography className={classes.heading}>{rows.name}</Typography>
          )}
          {renderPrimaryCheckBox()}
        </Box>
      ) : (
        !isDesktop && (
          <Typography className={classes.heading}>
            {rows.name} {rows.section}
          </Typography>
        )
      )}
      <Formik
        initialValues={{ ...createInitialState() }}
        onSubmit={rows.section === 'password' ? changePassword : updateInfo}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
        }) => {
          const formik = { touched, errors }
          return (
            <form onSubmit={handleSubmit}>
              <Grid container>
                {isDesktop && (
                  // Mobile
                  <Box display="flex" className={classes.header}>
                    <Button
                      className={classes.cancelBtnMobile}
                      color="default"
                      onClick={closeModal}
                      disableElevation
                    >
                      {t('cancel btn')}
                    </Button>
                    <Typography className={classes.mobileHeading}>
                      {rows.name}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.saveBtnMobile}
                      type="submit"
                      disableElevation
                    >
                      {t('save btn')}
                    </Button>
                  </Box>
                )}
                {rows.rows.map(
                  (value) =>
                    !value.notInModal &&
                    buildField(
                      value,
                      handleChange,
                      values,
                      setFieldValue,
                      formik
                    )
                )}
              </Grid>
              {!isDesktop && <Divider className={classes.divider} />}
              {
                // Desktop
                !isDesktop && (
                  <Grid container>
                    {showRemoveBtn && (
                      <Grid sm={4}>
                        <Button
                          onClick={onRemove}
                          variant="outlined"
                          color="secondary"
                          className={`${classes.removeBtn}`}
                        >
                          {t('exact del').toUpperCase()}
                        </Button>
                      </Grid>
                    )}
                    <Grid sm={showRemoveBtn ? 8 : 12}>
                      <Box display="flex" flexDirection="row-reverse">
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          className={classes.saveBtn}
                          type="submit"
                        >
                          {t('save btn')} {rows.name}
                        </Button>
                        <Button
                          className={classes.cancelBtn}
                          color="default"
                          onClick={closeModal}
                          disableElevation
                        >
                          {t('cancel btn')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )
              }
              {isDesktop && showRemoveBtn && (
                <Grid container justifyContent="center">
                  <Grid sm={12}>
                    <Button
                      onClick={onRemove}
                      variant="outlined"
                      color="secondary"
                      className={`${classes.removeBtn}`}
                      style={{ width: '358px' }}
                    >
                      {t('exact del')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default ModalEditInfo
