import React from 'react'
import { Box, Typography, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  hotelPayNotify: {
    backgroundColor: 'rgba(255,239,214,0.5)',
  },
  noteText: {
    fontSize: '16px',
    lineHeight: '1.5',
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
  },
}))

const NotifyBlock = ({ noSubNote, noteType, noteText }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box>
      {!!noteType && classes[noteType] && (
        <Paper elevation={0} className={`${classes[noteType]}`}>
          <Box p={2}>
            <Typography className={classes.noteText}>
              <span className={classes.bold}>
                {!noSubNote ? `${t('please note')}:` : ''}{' '}
              </span>
              <span className={noteType === 'paper' ? classes.bold : null}>
                {noteText}
              </span>
            </Typography>
          </Box>
        </Paper>
      )}
    </Box>
  )
}
export default NotifyBlock
